
export interface Point {
    x?: number;
    y?: number;
}

export class Vector {
    x: number;
    y: number;
    
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
    
    add(v: Vector): Vector {
        return new Vector(this.x + v.x, this.y + v.y);
    }
    
    subtract(v: Vector): Vector {
        return new Vector(this.x - v.x, this.y - v.y);
    }
    
    multiply(s: number): Vector {
        return new Vector(this.x * s, this.y * s);
    }
    
    divide(s: number): Vector {
        return new Vector(this.x / s, this.y / s);
    }
    
    length(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    
    normalize(): Vector {
        const m = this.length();
        if (m !== 0) {
            return this.divide(m);
        }
        return this;
    }
    
    limit(max: number): Vector {
        if (this.length() > max) {
        return this.normalize().multiply(max);
        }
        return this;
    }
    
    heading(): number {
        return Math.atan2(this.y, this.x);
    }
    
    rotate(angle: number): Vector {
        const newHeading = this.heading() + angle;
        const mag = this.length();
        return new Vector(Math.cos(newHeading) * mag, Math.sin(newHeading) * mag);
    }
    
    distance(v: Vector): number {
        return Math.sqrt(Math.pow(this.x - v.x, 2) + Math.pow(this.y - v.y, 2));
    }
    
    dot(v: Vector): number {
        return this.x * v.x + this.y * v.y;
    }

    cross(v: Vector): Vector {
        return new Vector(this.x * v.y - this.y * v.x, this.y * v.x - this.x * v.y);
    }
    
    perpendicularClockwise(): Vector {
        return new Vector(-this.y, this.x);
    }

    perpendicularCounterClockwise(): Vector {
        return new Vector(this.y, -this.x);
    }

    static fromAngle(angle: number, magnitude: number): Vector {
        return new Vector(Math.cos(angle) * magnitude, Math.sin(angle) * magnitude);
    }

    static lerp(v1: Vector, v2: Vector, amt: number): Vector {
        return v1.add(v2.subtract(v1).multiply(amt));
    }
    
    static angleBetween(v1: Vector, v2: Vector): number {
        return Math.acos(v1.normalize().dot(v2.normalize()));
    }
    
    static fromPoints(p1: Point, p2: Point): Vector {
        return new Vector(p2.x - p1.x, p2.y - p1.y);
    }

    static cross(v1: Vector, v2: Vector): Vector {
        return new Vector(v1.x * v2.y - v1.y * v2.x, v1.y * v2.x - v1.x * v2.y);
    }

    static dot(v1: Vector, v2: Vector): number {
        return v1.x * v2.x + v1.y * v2.y;
    }
}