/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataEntry, RunStats, ValueRange } from "./types";

type BaseTypeOfArray<T> = T extends Array<infer I> ? I : "never";

declare global {
    interface Generator<T, TReturn, TNext> {
        first(predicate?: (item: T) => boolean): T | null;
        last(predicate?: (item: T) => boolean): T;

        contains(item: T): boolean;
        /* where(predicate: (item: T) => boolean): Generator<T, TReturn, TNext>;*/
        where(predicate: (current: T, previous: T) => boolean): Generator<T, TReturn, T>;
        select<U>(selector: (item: T) => U): Generator<U, any, unknown>;
        calculateStats(selector: (item: T) => number): RunStats;

        skip(count: number): Generator<T, any, unknown>;
        take(count: number): Generator<T, any, unknown>;

        min(selector: (item: T) => number): number;
        max(selector: (item: T) => number): number;

        minBy(selector: (item: T) => number): T;
        maxBy(selector: (item: T) => number): T;
        range(selector: (item: T) => number): ValueRange<number>;
        rangeBy(selector: (item: T) => number): ValueRange<T>;

        any(predicate?: (item: T) => boolean): boolean;
        count(predicate?: (item: T) => boolean): number;

        groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;
        
        toArray?(): T[];
    }

    interface Array<T> {
        where(predicate: (item: T, previous: T) => boolean): Generator<T, any, unknown>;
        select<U>(selector: (item: T) => U): Generator<U, any, unknown>;
        selectMany<U>(selector: (item: T) => U[] | Generator<U, any, unknown>): Generator<U, any, unknown>;

        minBy(selector: (item: T) => number): T;
        maxBy(selector: (item: T) => number): T;

        min(selector: (item: T) => number): number;
        max(selector: (item: T) => number): number;

        range(selector: (item: T) => number): ValueRange<number>;
        rangeBy(selector: (item: T) => number): ValueRange<T>;

        skip(count: number): Generator<T, any, unknown>;
        take(count: number): Generator<T, any, unknown>;

        calculateStats(selector: (item: T) => number): RunStats;

        first(predicate?: (item: T) => boolean): T | null;
        last(predicate?: (item: T) => boolean): T;

        contains(item: T): boolean;

        any(predicate?: (item: T) => boolean): boolean;
        count(predicate?: (item: T) => boolean): number;

        groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;

        toArray?(): T[];
    }

    interface Iterable<T> {
        any(predicate: (item: T) => boolean): boolean;
        groupBy<TKey>(selector: (item: T) => TKey): Map<TKey, T[]>;
    }

    interface IterableIterator<T> {
        toArray(): BaseTypeOfArray<T>[];
    }
}

const skiGenerator = function* (count: number) {
    for (const item of this) {
        if (--count < 0) {
            yield item;
        }
    }
}

const takeGenerator = function* (count: number) {
    for (const item of this) {
        if (--count >= 0) {
            yield item;
        }
    }
}

const whereGenerator = function*<T>(predicate: (item: T, previous: T) => boolean) {
    let prev: T = null;

    for (const item of this) {
        if (predicate(item, prev)) {
            yield item;
            prev = item;
        }
    }
};

//const whereWithPrevGenerator = function*<T>(this: Generator<T, any, unknown>, predicate: (current: T, previous: T) => boolean) {
//    const first = this.next();

//    if (!first.done) {
//        let prev = first.value;
//        yield prev;

//        for (const item of this) {
//            if (predicate(item, prev))
//                yield item;

//            prev = item;
//        }
//	}
//};

const selectGenerator = function*<T, U>(selector: (item: T) => U): Generator<U, any, unknown> {
    for (const item of this) {
        yield selector(item);
    }
};

const selectManyGenerator = function*<T, U>(selector: (item: T) => U[]) {
    for (const item of this) {
        for (const sub of selector(item)) {
            yield sub;
        }
    }
};

const maxByFunction = function <T>(this: Iterable<T>, selector: (item: T) => number): T {
    let maxValue = Number.MIN_VALUE;
    let max: T = null;

    for (const value of this) {
        const selectValue = selector(value);
        if (selectValue > maxValue) {
            maxValue = selectValue;
            max = value;
        }
    }

    return max;
}

const maxFunction = function <T>(this: Iterable<T>, selector: (item: T) => number): number {
    let maxValue = Number.MIN_VALUE;

    for (const value of this) {
        const selectValue = selector(value);
        if (selectValue > maxValue) {
            maxValue = selectValue;
        }
    }

    return maxValue;
}

const minByFunction = function <T>(this: Iterable<T>, selector: (item: T) => number): T {
    let minValue = Number.MAX_VALUE;
    let min: T = null;

    for (const value of this) {
        const selectValue = selector(value);
        if (selectValue < minValue) {
            minValue = selectValue;
            min = value;
        }
    }

    return min;
}

const minFunction = function <T>(this: Iterable<T>, selector: (item: T) => number): number {
    let minValue = Number.MAX_VALUE;

    for (const value of this) {
        const selectValue = selector(value);
        if (selectValue < minValue) {
            minValue = selectValue;
        }
    }

    return minValue;
}

const rangeFunction = function <T>(this: Iterable<T>, selector: (item: T) => number): ValueRange<number> {
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;

    for (const value of this) {
        const selectValue = selector(value);
        if (selectValue < min) {
            min = selectValue;
        }

        if (selectValue > max) {
            max = selectValue;
        }
    }

    return {
        min,
        max
    };
}

const rangeByFunction = function <T>(this: Iterable<T>, selector: (item: T) => number): ValueRange<T> {
    let minValue = Number.MAX_VALUE;
    let min: T = null;
    let maxValue = Number.MIN_VALUE;
    let max: T = null;

    for (const value of this) {
        const selectValue = selector(value);
        if (selectValue < minValue) {
            minValue = selectValue;
            min = value;
        }

        if (selectValue > maxValue) {
            maxValue = selectValue;
            max = value;
        }
    }

    return {
        min,
        max
    };
}

const calculateStatsFunction = function <T>(this: T[] | Generator<T, any, unknown>, selector: (item: T) => number): RunStats {
    return calculateStats(this.select(selector));
}

/*
 * Calculates stats using the Welford algorithm
 */
export function calculateStats(data: Iterable<number>): RunStats {
    let m = 0.0;
    let s = 0.0;
    let k = 0;
    let sum = 0.0;
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;

    for (const value of data) {
        ++k;
        const prevM = m;

        m += (value - prevM) / k;
        s += (value - prevM) * (value - m);

        sum += value;
        if (value < min)
            min = value;

        if (value > max)
            max = value;
    }

    const mean = sum / k;
    const stdDev = Math.sqrt(s / k); // whole population std dev (k-1) vs subset (k-2)

    return {
        minimum: min,
        maximum: max,
        stdDevLow: mean - stdDev,
        stdDevHigh: mean + stdDev
    };
}

const firstFunction = function <T>(this: Iterable<T>, predicate?: (item: T) => boolean): T | null {
    if (!this)
        return null;

    if (predicate) {
        for (const val of this) {
            if (predicate(val)) {
                return val;
            }
        }

        return null;
    } else {
        if (Array.isArray(this)) {
            return this[0];
        }

        for (const val of this) {
            return val;
        }

        return null;
    }
}

const lastFunction = function <T>(this: Iterable<T> | T[], predicate?: (item: T) => boolean): T | null {
    if (!this)
        return null;

    if (Array.isArray(this)) {
        const array = this as T[];
        if (array.length < 1)
            return null;

        if (predicate) {
            for (let i = array.length - 1; i >= 0; --i) {
                if (predicate(array[i])) {
                    return array[i];
                }
            }

            return null;
        } else {
            return array[array.length - 1];
        }
    } else {

        const array = Array.from(this);

        return Array.from(this).reverse().first(predicate);
    }
}


const containsFunction = function <T>(this: Iterable<T>, item: T): boolean {
    for (const value of this) {
        if (value === item)
            return true;
    }

    return false;
}

const countFunction = function <T>(this: Iterable<T>, predicate?: (item: T) => boolean): number {
    let count = 0;
    if (predicate) {
        for (const val of this) {
            if (predicate(val))
                ++count;
        }
    } else {
        for (const _ of this) {
            ++count;
        }
    }

    return count;
}

const anyFunction = function <S extends Iterable<T>, T>(this: S, predicate?: (item: T) => boolean): boolean {
    if (predicate) {
        for (const val of this) {
            if (predicate(val)) {
                return true;
            }
        }
    } else {
        for (const _ of this) {
            return true;
        }
    }

    return false;
}

const groupByFunction = function <T, TKey>(this: T[] | Iterable<T> | IterableIterator<T>, selector: (item: T) => TKey): Map<TKey, T[]> {
    if (Array.isArray(this)) {
        return this.reduce((entryMap, e) => {
            const key = selector(e);
            return entryMap.set(key, [...entryMap.get(key) || [], e])
        }, new Map<TKey, T[]>());
    } else {
        const map = new Map<TKey, T[]>();
        for (const item of this) {
            const key = selector(item);
            const grp = map.get(key);
            if (!grp) {
                map.set(key, [item]);
            } else {
                grp.push(item);
            }           
        }

        return map;
    }
}

const toArrayFunction = function <T>(): T[] {
    return Array.from(this);
};

function flattenIterable<T>(): T[] {
    const iter = <IterableIterator<T[]>>this;
    const array = [];
    for (const grp of iter) {
        for (const item of grp) {
            array.push(item);
        }
    }
    return array;
}

// get the prototype of the basic generator
const gen = function* <T>(): Generator<T, any, T> {
    yield null as T;
    yield null as T;
    return null as T;

    //throw null as T;
};

const Generator = Object.getPrototypeOf(gen);

//const Generator = Object.getPrototypeOf(function* <T> () { yield null as T; });

// extend generator/Array with out LINQ methods
if (!Generator.prototype.where) {
    Generator.prototype.where = whereGenerator;
    Generator.prototype.select = selectGenerator;
    Generator.prototype.selectMany = selectManyGenerator;
    Generator.prototype.maxBy = maxByFunction;
    Generator.prototype.minBy = minByFunction;
    Generator.prototype.min = minFunction;
    Generator.prototype.max = maxFunction;
    Generator.prototype.range = rangeFunction;
    Generator.prototype.rangeBy = rangeByFunction;
    Generator.prototype.skip = skiGenerator;
    Generator.prototype.take = takeGenerator;
    Generator.prototype.calculateStats = calculateStatsFunction;
    Generator.prototype.contains = containsFunction;
    Generator.prototype.count = countFunction;
    Generator.prototype.any = anyFunction;
    Generator.prototype.first = firstFunction;
    Generator.prototype.last = lastFunction;
    Generator.prototype.groupBy = groupByFunction;
    Generator.prototype.toArray = toArrayFunction;
}

if (!Array.prototype.where) {
    /* eslint-disable no-extend-native */
    Array.prototype.where = whereGenerator;
    Array.prototype.select = selectGenerator;
    Array.prototype.selectMany = selectManyGenerator;
    Array.prototype.minBy = minByFunction;
    Array.prototype.maxBy = maxByFunction;
    Array.prototype.min = minFunction;
    Array.prototype.max = maxFunction;
    Array.prototype.range = rangeFunction;
    Array.prototype.rangeBy = rangeByFunction;
    Array.prototype.skip = skiGenerator;
    Array.prototype.take = takeGenerator;
    Array.prototype.calculateStats = calculateStatsFunction;
    Array.prototype.first = firstFunction;
    Array.prototype.last = lastFunction;
    Array.prototype.contains = containsFunction;
    Array.prototype.any = anyFunction;
    Array.prototype.count = countFunction;
    Array.prototype.groupBy = groupByFunction;
    Array.prototype.toArray = toArrayFunction;
}

const iterator = Object.getPrototypeOf(Object.getPrototypeOf([][Symbol.iterator]()));
if (!iterator.toArray) {
    iterator.toArray = flattenIterable;
}

/* eslint-enable no-extend-native */
class Enumerable<T> {
    private _iterator: Iterable<T>;

    constructor(iterator: Iterable<T>) {
        // assuming iterator is some sort of iterable
        this._iterator = iterator;
    }

    *[Symbol.iterator]() {
        yield* this._iterator;
    }

    // Static (and private) helper generator functions
    static *_filter<T>(iterator: Iterable<T>, predicate: (item: T) => boolean): Iterable<T> {
        for (const value of iterator) {
            if (predicate(value)) {
                yield value;
            }
        }
    }

    static *_map<T, U>(iterator: Iterable<T>, mapperFunc: (item: T) => U): Iterable<U> {
        for (const value of iterator) {
            yield mapperFunc(value);
        }
    }
    static *_take<T>(iterator: Iterable<T>, count: number): Iterable<T> {
        let index = -1;
        for (const value of iterator) {
            if (++index >= count) {
                break;
            }

            yield value;
        }
    }

    // Instance methods wrapping functional helpers which allow for chaining
    // The existing iterator is transformed by the helper generator function.
    // The operations haven't actually happened yet, just the "instructions"
    filter(predicate: (item: T) => boolean) {
        this._iterator = Enumerable._filter(this._iterator, predicate);
        return this;
    }

    map<U>(mapper: (item: T) => U): Enumerable<U> {
        const iterator = Enumerable._map(this._iterator, mapper);

        return new Enumerable<U>(iterator);
    }

    take(count: number) {
        const iterator = Enumerable._take(this._iterator, count);
        return new Enumerable(iterator);
    }
}

export { }