import { combineReducers, Reducer, Action } from 'redux';
import historyReducer from './history/historySlice';
import viewerReducer from './viewer/viewerSlice';
import filterReducer from './history/filterToolbarSlice';
import serviceWorkerReducer from './serviceWorkerSlice';
import { BaseState, ServiceWorkerState, RootState, AllActions, AllActionTypes, ErrorState, errorReducer, SharedActions } from "./types";
import { FilterToolbarState, HistoryState } from "./history/types";
import { ViewerState } from './viewer/types';
import accountReducer from './account/accountSlice';
import { AccountState } from './account/types';

type ActionPrefix = 'filter/' | 'history/' | 'viewer/' | 'ACCOUNT';

/**
 * higher-order reducer function 
 * @param reducer 
 * @param prefix 
 */
function reduceLatestAction<T extends AllActionTypes, A extends Action<T>, S extends BaseState<T>>(reducer: Reducer<S, A>, prefix: ActionPrefix) {
	return (state: S, action: A): S => {
		let newState = reducer(state, action);

		if ((<string>action.type).startsWith(prefix)) {
			newState = {
				...newState,
				latestAction: action.type
			};			
		}

		return newState;
	};
}

const appReducer: Reducer<RootState, AllActions> = combineReducers<RootState, AllActions>({
	filterToolbar: reduceLatestAction(filterReducer, 'filter/'),
	
    history: reduceLatestAction(historyReducer, 'history/'),
    viewer: reduceLatestAction(viewerReducer, 'viewer/'),
    serviceWorker: serviceWorkerReducer,
	account: reduceLatestAction(accountReducer, 'ACCOUNT'),
	error: errorReducer
});

const rootReducer = (state: RootState, action: AllActions): RootState => {
	switch (action.type) {
		case SharedActions.USER_LOGGED_OUT: {
			console.log('user logged out - handling action');
			return state;
		}
	}
	return appReducer(state, action);
};

/***** core selector functions *****/

/**
 *	Selector function to retrieve the HistoryState
 */
const selectHistory = (state: RootState): HistoryState => state.history;
const selectFilter = (state: RootState): FilterToolbarState => state.filterToolbar;
const selectViewer = (state: RootState): ViewerState => state.viewer;
const selectServiceWorker = (state: RootState): ServiceWorkerState => state.serviceWorker;
const selectAccount = (state: RootState): AccountState => state.account;
const selectError = (state: RootState): ErrorState => state.error;

export { rootReducer, selectFilter, selectHistory, selectViewer, selectServiceWorker, selectAccount, selectError };