import React, { ChangeEvent, useEffect, useState } from 'react';
import Loader from '../controls/Loader';
import Colors from '../core/Colors';

import { useAuth0 } from '@auth0/auth0-react';
// prettier-ignore
import {
    Box, CircularProgress, Fab, FormControl, Grid, IconButton,
    InputAdornment, InputLabel, makeStyles, OutlinedInput, TextField, Theme, Typography, withStyles,
} from '@material-ui/core';

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import SendIcon from '@material-ui/icons/Send';
import SyncIcon from '@material-ui/icons/Sync';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectAccount } from '../../state/reducer';
import { AccountActionTypes, initializeAccount, PropertyTypes, setPropertyNewValue, setRefreshing } from '../../state/account/types';
import { ThunkDispatch } from 'redux-thunk';
import { updateProperty } from '../../state/account/accountSlice';
import { RootState } from '../../state/types';
import useGetAccessToken from '../../helpers/hooks/useGetAccessToken';

const useStyles = makeStyles((theme: Theme) => ({
    errorMsg: {
        fontSize: '1rem',
        marginTop: 12,
        marginBottom: 4,
        [theme.breakpoints.only('xs')]: {
            fontSize: '0.75rem',
            marginTop: 4,
            marginBottom: -4,
        }
    },
    textInput: {
        [theme.breakpoints.down('xs')]: {
            padding: '18.5px 14px'
        }
    },
    emailText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14
        }
    },
    refreshButton: {
        position: 'absolute',
        backgroundColor: '#fafafa',
        top: 8,
        right: 8,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#fafafa',
            '&:hover': {
                backgroundColor: '#fafafa'
            },
            top: -24
        }
    },
    refreshProgress: {
        position: 'absolute',
        top: 8,
        right: 8,
        width: 48,
        height: 48,
        [theme.breakpoints.down('xs')]: {
            top: -24
        }
    }
}));

const Title = withStyles((theme) => ({
    root: {
        position: 'absolute',
        left: 48,
        top: -16,
        paddingLeft: 16,
        paddingRight: 24,
        backgroundColor: '#fafafa',

        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            left: 24,
            top: -12,
            paddingLeft: 12,
        },
    },
}))(Typography);

const UserInfo: React.FC = (): React.ReactElement => {
    const classes = useStyles();
    const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    const getAccessToken = useGetAccessToken();

    const [isBusy, setBusy] = useState<boolean>(true);

    const state = useSelector(selectAccount, shallowEqual);
    const dispatch = useDispatch<ThunkDispatch<RootState, undefined, AccountActionTypes>>();

    useEffect(() => {
        console.log('mounting UserInfo');
        document.title = 'ReportViewer - User Info';
        return () => {
            console.log('un-mounting UserInfo');
        };
    }, []);

    //useEffect(() => {
    //    fetch(`account/user/${user.sub}?email=pearce.fleming123@gmail.com`, {
    //        method: "put",
    //        headers: {
    //            Authorization: `Bearer ${accessToken}`
    //        }
    //        //body: JSON.stringify({
    //        //    name: "pearce fleming",
    //        //    email: "pearce.fleming@paveset.com.au",
    //        //    phone: "0408059767"
    //        //})
    //    }).then(resp => {
    //        if (resp.status === 200) {
    //            console.log('Successfully updated user');
    //        } else {
    //            console.log('Failed updating user');
    //        }
    //    });
    //}, [accessToken, user]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            setBusy(false);
        }
    }, [isLoading, isAuthenticated, user]);

    useEffect(() => {
        console.log(user);

        if (user) {
            let firstName = user.given_name;
            let lastName = user.family_name;

            if (!firstName || !lastName) {
                const split = (user.name as string).split(' ');
                firstName = split[0];
                lastName = split.length > 1 ? split[1] : null;
            }

            dispatch(initializeAccount(user.sub, user.name, firstName, lastName, user.phone_number, user.email, user.email_verified));
        }
    }, [dispatch, user]);

    const updateUser = () => {
        if (getAccessTokenSilently && isAuthenticated) {
            dispatch(setRefreshing(true));

            return getAccessTokenSilently({
                ignoreCache: true
            }).then(resp => {
                dispatch(setRefreshing(false));
            });
        }

        return Promise.resolve();
    };

    const showLoader = () => {
        return <Loader centered text='Retrieving User Info' size={50} type='circle' color={Colors.accent} />;
    };

    const showError = () => {
        return (
            <Typography align='center' className={classes.errorMsg} variant='h6'>
                ERROR
            </Typography>
        );
    };

    const handleChange = (prop: PropertyTypes) => (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setPropertyNewValue(state[prop], e.target.value));
    };

    const saveProperty = (prop: PropertyTypes) => () => {
        dispatch(updateProperty(getAccessToken, state.id, state[prop]));
    };

    const getPrettyPropertyName = (prop: PropertyTypes): string => {
        switch (prop) {
            case "firstName":
                return "First Name";
            case "lastName":
                return "Last Name";
            case "phone":
                return "Phone Number";
            case "email":
                return "Email Address";
            case "name":
                return "Full Name";
        }
    };

    const hasPropertyChanged = (prop: PropertyTypes): boolean => {
        if (!state[prop].value) {
            return !!state[prop].newValue;
        }

        return state[prop].value != state[prop].newValue;
    };

    const getBasicUserProperty = (prop: PropertyTypes) => {
        const prettyName = getPrettyPropertyName(prop);
        const widthCalculator = document.createElement("canvas").getContext("2d");
        widthCalculator.font = "16px sans-serif";

        const textMetrics = widthCalculator.measureText(prettyName);

        //console.log(state[prop].value);
        //console.log(state[prop].newValue);

        return (
            <FormControl fullWidth variant='outlined'>
                <InputLabel style={{ width: 150 }} htmlFor={`input-${prop}`}>
                    {getPrettyPropertyName(prop)}
                </InputLabel>

                <OutlinedInput
                    classes={{ input: classes.textInput }}
                    id={`input-${prop}`}
                    type='text'
                    value={state[prop].newValue}
                    onChange={handleChange(prop)}
                    endAdornment={
                        <InputAdornment position='end'>
                            {hasPropertyChanged(prop) && (
                                <Fab
                                    color='primary'
                                    style={{ marginRight: -8 }}
                                    disabled={!state[prop] || state[prop].isUpdating}
                                    onClick={saveProperty(prop)}
                                    onMouseDown={e => e.preventDefault()}
                                    size='small'>
                                    <SaveOutlinedIcon style={{ fontSize: 20, marginLeft: 2 }} />
                                </Fab>
                            )}
                            {state[prop] && state[prop].isUpdating && (
                                <CircularProgress style={{ position: "absolute", right: 2, top: 4 }} size={48} />
                            )}
                        </InputAdornment>
                    }

                    labelWidth={textMetrics.width + 30}
                />
            </FormControl>
        );
    }
    
    const getValidationMessage = () => {
        if (state.email && !state.email.emailVerified) {
            if (state.email.sentVerifyEmail) {
                return 'Verification Email Sent';
            } else {
                return 'Need to Send Verification Email';
            }
        }

        return null;
    };

    const showBody = () => {
        console.log('showing body');
        return (
            <div>
                <Box
                    style={{ borderStyle: 'solid', borderWidth: 1, display: 'flex', position: 'relative' }}
                    borderRadius={16}
                    borderColor={Colors.grey500}
                    padding={2}
                    paddingTop={3}
                    marginTop={2}>
                    <Title variant='h6'>User Details</Title>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography display='inline' variant='subtitle2'>
                                Unique User ID:
                            </Typography>

                            <Typography display='inline' style={{ marginLeft: 8 }} variant='caption'>
                                {state.id.substring(state.id.indexOf('|') + 1)}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {getBasicUserProperty('firstName')}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {getBasicUserProperty('lastName')}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {getBasicUserProperty('phone')}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id='input-email'
                                label='Email Address'
                                value={state.email.newValue}
                                onChange={handleChange('email')}
                                fullWidth
                                InputProps={{
                                    classes: { input: classes.emailText },
                                    labelWidth: 140,
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {/* Send Verification Email Adornment */}
                                            {state.email && !state.email.emailVerified && (
                                                <Fab
                                                    color='primary'
                                                    style={{ marginRight: hasPropertyChanged('email') ? 6 : -8 }}
                                                    disabled={!state.email || state.email.sendingVerifyEmail}
                                                    onClick={saveProperty('email')}
                                                    onMouseDown={e => e.preventDefault()}
                                                    size='small'>
                                                    <SendIcon style={{ fontSize: 18, marginLeft: 2 }} />
                                                </Fab>
                                            )}

                                            {state.email && state.email.sendingVerifyEmail && (
                                                <CircularProgress
                                                    style={{
                                                        position: 'absolute',
                                                        right: hasPropertyChanged('email') ? 48 : 2,
                                                        top: 4,
                                                        height: 48,
                                                        width: 48
                                                    }}
                                                />
                                            )}
                                            
                                            {/* Save Updates Adornment */}
                                            {hasPropertyChanged('email') && <Fab
                                                    color='primary'
                                                    style={{ marginRight: -8 }}
                                                    disabled={!state.email || state.email.isUpdating}
                                                    onClick={saveProperty('email')}
                                                    onMouseDown={e => e.preventDefault()}
                                                    size='small'>
                                                    <SaveOutlinedIcon style={{ fontSize: 20, marginLeft: 2 }} />
                                                </Fab>
                                            }

                                            {state.email && state.email.isUpdating && (
                                                <CircularProgress
                                                    style={{
                                                        position: 'absolute',
                                                        right: 2,
                                                        top: 4,
                                                        width: 48,
                                                        height: 48
                                                    }}
                                                />
                                            )}
                                        </InputAdornment>
                                    )
                                }}
                                variant='outlined'
                                helperText={getValidationMessage()}
                            />
                        </Grid>
                    </Grid>

                    <IconButton className={classes.refreshButton} onClick={updateUser}>
                        <SyncIcon style={{ fontSize: 24 }} />
                    </IconButton>

                    {state.isRefreshing && <CircularProgress style={{ width: 48, height: 48 }} className={classes.refreshProgress} />}
                </Box>

                <Box
                    style={{ borderStyle: 'solid', borderWidth: 1, display: 'flex', position: 'relative' }}
                    borderRadius={16}
                    borderColor={Colors.grey500}
                    padding={0}
                    marginTop={4}>
                    <Title variant='h6'>Change Password</Title>

                    <div style={{ height: 150 }} />
                </Box>

                <Box
                    style={{ borderStyle: 'solid', borderWidth: 1, display: 'flex', position: 'relative' }}
                    borderRadius={16}
                    borderColor={Colors.grey500}
                    padding={0}
                    marginTop={4}>
                    <Title variant='h6'>Extra Settings</Title>

                    <div style={{ height: 150 }} />
                </Box>
            </div>
        );
    };

    return (
        <div>
            {isBusy && showLoader()}
            {!isBusy && showBody()}
        </div>
    );
};

export default UserInfo;
