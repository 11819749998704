import { ServiceWorkerActionTypes, ServiceWorkerState, ServiceWorkerActions } from './types';

const initialState: ServiceWorkerState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
}

function serviceWorkerReducer(state = initialState, action: ServiceWorkerActionTypes): ServiceWorkerState {
    switch (action.type) {
        case ServiceWorkerActions.SW_INIT:
            console.log('handling SW_INIT action');
            return {
                ...state,
                serviceWorkerInitialized: !state.serviceWorkerInitialized
            };
        case ServiceWorkerActions.SW_UPDATE:
            console.log('handling SW_UPDATE action');
            return {
                ...state,
                serviceWorkerUpdated: !state.serviceWorkerUpdated,
                serviceWorkerRegistration: action.payload
            };
        default:
            return state;
    }
}

export default serviceWorkerReducer;