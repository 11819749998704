import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Loader from "./controls/Loader";
import Colors from "./core/Colors";
import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
//prettier-ignore
import {
    Snackbar, Button, Divider, Drawer, Hidden, IconButton, List, ListItem,
    ListItemText, ListItemIcon, Toolbar, CssBaseline, AppBar, Typography, CircularProgress,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import HistoryIcon from "@material-ui/icons/History";
import TimelineIcon from "@material-ui/icons/Timeline";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ErrorBoundary from "./core/ErrorBoundary";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import { userLoggedOut, userLoggedIn } from "../state/types";
import { getCacheKeyForURL } from "workbox-precaching";
import useGetAccessToken from "../helpers/hooks/useGetAccessToken";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerList: {
        height: `calc(100vh - 64px - 100px)`,
        overflowY: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 48px - 100px)'
        }
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            //width: `calc(100% - ${drawerWidth}px)`,
            //marginLeft: drawerWidth
            //zIndex: theme.zIndex.drawer + 1
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    // necessary for content to be below app bar
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        ...theme.mixins.toolbar
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            top: "64px",
            boxShadow: "0px 0 10px -2px #AAA"
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1, 2, 2),
        marginTop: "56px",
        maxWidth: "calc(100% - 240px)",
        [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
            marginTop: "48px"
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "64px"
        },
        [theme.breakpoints.down("sm")]: {
            paddingBottom: 8,
            maxWidth: "100%"
        }
    },
    title: {
        flexGrow: 1
    },
    menuTitle: {
        color: "white",
        paddingTop: "1rem"
    },
    loader: {
        width: "60px",
        height: "60px"
    },
    importProgress: {
        color: Colors.accent,
        position: "absolute",
        right: 8,
        top: 12
    }
}));

function Layout(props) {
    const { location } = props;
    const { historyLoaded, viewerLoaded, uploaderLoaded } = props;

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const dispatch = useDispatch();
    
    const isServiceWorkerInitialized = useSelector(
        state => state.serviceWorker.serviceWorkerInitialized
    );
    const isServiceWorkerUpdated = useSelector(
        state => state.serviceWorker.serviceWorkerUpdated
    );
    const serviceWorkerRegistration = useSelector(
        state => state.serviceWorker.serviceWorkerRegistration
    );

    useEffect(() => {
        console.log(
            `service-worker initialized: ${isServiceWorkerInitialized}`
        );
        console.log(`service-worker updated: ${isServiceWorkerUpdated}`);
        console.log(`service-worker registration:`);
        console.log(serviceWorkerRegistration);
    }, [
        isServiceWorkerInitialized,
        isServiceWorkerUpdated,
        serviceWorkerRegistration
    ]);

    const [showAlert, setShowAlert] = useState(true);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const closeDrawer = () => {
        if (mobileOpen) setMobileOpen(false);
    };

    const isActive = name => {
        return location.pathname.endsWith(name);
    };

    const {
        isLoading,
        isAuthenticated,
        error,

        loginWithRedirect,
        logout,
        getAccessTokenSilently
    } = useAuth0();

    const getAccessToken = useGetAccessToken();

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(userLoggedIn(getAccessToken));
        }

        // if (error) {
        //     console.log(`Auth Error: ${error.message}`);
        //     console.log(error.stack);
        // }

        // if (isAuthenticated) {
        //     console.log("retrieving access token silently");

        //     /* don't request scopes (scope: "list:reports read:reports") because we use RBAC in auth token (permissions claim) */
        //     getAccessTokenSilently({
        //         ignoreCache: true               
        //     }).then(token => {
        //         console.log("setting access token");
        //         context.tokenContext.current.setAccessToken(token);
        //     }).catch(error => {
        //         handleLogout();
        //     });
        // } else {
        //     console.log("not authenticated... setting access token to empty");
        //     context.tokenContext.current.setAccessToken("");
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, error, dispatch]);

    const drawer = (
        <div>
            <Hidden mdUp implementation='css'>
                <div className={classes.toolbar}>
                    <Typography variant='h6' noWrap align='center' className={classes.menuTitle}>
                        Paveset Reporter
                    </Typography>
                </div>

                <Divider />
            </Hidden>

            <List className={classes.drawerList}>
                <ListItem button component={Link} to='/' onClick={closeDrawer} selected={isActive("/")}>
                    <ListItemIcon>
                        <HomeOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItem>

                <ListItem button component={Link} to='/history' onClick={closeDrawer} selected={isActive("/history")} disabled={!isAuthenticated}>
                    <ListItemIcon>
                        <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary='Report History' />
                    {!historyLoaded && <CircularProgress size={24} className={classes.importProgress} />}
                </ListItem>

                <ListItem button component={Link} to='/viewer' onClick={closeDrawer} selected={isActive("/viewer")} disabled={!isAuthenticated}>
                    <ListItemIcon>
                        <TimelineIcon />
                    </ListItemIcon>
                    <ListItemText primary='Report View' />
                    {!viewerLoaded && <CircularProgress size={24} className={classes.importProgress} />}
                </ListItem>

                <ListItem button component={Link} to='/uploader' onClick={closeDrawer} selected={isActive("/uploader")} disabled={!isAuthenticated}>
                    <ListItemIcon>
                        <CloudUploadOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Upload Report' />
                    {!uploaderLoaded && <CircularProgress size={24} className={classes.importProgress} />}
                </ListItem>
            </List>

            <Button
                disabled={!isAuthenticated}
                component={Link}
                to='/user'
                onClick={closeDrawer}
                variant='contained'
                style={{
                    width: 240,
                    position: "fixed",
                    display: "block",
                    bottom: 0,
                    left: 0,
                    height: 100,
                    borderRadius: 0,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }}
                color='primary'>
                <AccountCircleOutlinedIcon
                    style={{
                        fontSize: 48,
                        left: "calc(50% - 24px)",
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 8
                    }}
                />
                <Typography
                    variane='body1'
                    align='center'
                    style={{
                        textTransform: "none",
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 130,
                        marginTop: 8
                    }}>
                    Account Details
                </Typography>
            </Button>
        </div>
    );

    const handleLogout = () => {
        logout({ returnTo: window.location.origin });
        dispatch(userLoggedOut());
    };

    const handleLogin = () => {
        loginWithRedirect();
    };

    const authDiv = () => {
        return (
            <div>
                {/*{user.name}{' '}*/}
                <Button
                    color='inherit'
                    onClick={handleLogout}>
                    Logout
                </Button>
            </div>
        );
    };

    const getToolbarButtons = () => {
        return (
            <>
                {isAuthenticated && authDiv()}
                {!isAuthenticated && (
                    <Button color='inherit' onClick={handleLogin}>
                        LOGIN
                    </Button>
                )}
            </>
        );
    };

    const getMainContent = () => {
        return (
            <>
                {isLoading && (
                    <Loader
                        type={"circle"}
                        centered={true}
                        size={50}
                        color={Colors.accent}
                        text='Checking Login Status'
                        classes={{ spinner: classes.loader }}
                    />
                )}
                {!isLoading && props.children}
            </>
        );
    };

    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: "SKIP_WAITING" });

            registrationWaiting.addEventListener("statechange", e => {
                if (e.target.state === "activated") {
                    console.log("forcing reload of page by service worker");

                    window.location.reload();
                }
            });
        }
    };

    const handleUpdateClicked = () => {
        updateServiceWorker();
    };

    return (
        <div className={classes.root}>
            {/* CSS core styling from material-ui */}
            <CssBaseline />

            {/*
             * The top app bar containing the header text (probably just the app name) and the login/logout button
             */}
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='start'
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant='h6' noWrap className={classes.title}>
                        Paveset Reporter
                    </Typography>

                    {getToolbarButtons()}
                </Toolbar>
            </AppBar>

            {/* navigation drawer(s) */}
            <nav className={classes.drawer}>
                {/*
                 * Drawer implementation for xs/mobile displays (hidden for sm+/non-mobile displays).
                 * The drawer is only displayed when the menu button is clicked and is closed when a menu item is clicked,
                 * or any part of the screen not inside the flyout is clicked
                 */}
                <Hidden mdUp implementation='css'>
                    <Drawer
                        variant='temporary'
                        anchor='left'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{ paper: classes.drawerPaper }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}>
                        {drawer}
                    </Drawer>
                </Hidden>

                {/*
                 * Drawer implementation for sm+/non-mobile displays (hidden for xs/mobile displays).
                 * The drawer is permanently displayed (no flyout functionality)
                 */}
                <Hidden smDown implementation='css'>
                    <Drawer
                        classes={{ paper: classes.drawerPaper }}
                        variant='permanent'
                        open>
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>

            {/*
             * render the main body of the website. This is the section which will get changed by navigating to different Routes
             *		-> there is also a Snackbar to display the refresh button when a service-worker update has been detected
             */}
            <main className={`${classes.content} `}>
                {/* popup message with refresh button for when a new service worker is detected */}
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={isServiceWorkerUpdated && showAlert}>
                    <Alert
                        variant='filled'
                        severity='info'
                        action={
                            <div style={{ marginLeft: 12, marginRight: 12 }}>
                                <Button
                                    color='inherit'
                                    size='small'
                                    onClick={handleUpdateClicked}>
                                    UPDATE
                                </Button>
                            </div>
                        }>
                        New Version is Ready! Click Update to Reload
                    </Alert>
                </Snackbar>

                {/* popup message for when the first service worker installation is detected */}
                <Snackbar
                    autoHideDuration={5000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={isServiceWorkerInitialized && showAlert}>
                    <Alert
                        variant='filled'
                        severity='info'
                        action={
                            <Button
                                color='inherit'
                                size='small'
                                onClick={() => setShowAlert(false)}>
                                <CloseIcon fontSize='inherit' />
                            </Button>
                        }>
                        Website has been saved for faster navigation
                    </Alert>
                </Snackbar>

                <ErrorBoundary>{getMainContent()}</ErrorBoundary>
            </main>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    location: PropTypes.object.isRequired,
    historyLoaded: PropTypes.bool,
    viewerLoaded: PropTypes.bool,
    uploaderLoaded: PropTypes.bool
};

export default withRouter(Layout);
