import { Reducer } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { UserInfo } from '../../helpers/types';
import { selectAccount } from '../reducer';
import { RootState } from '../types';
import { AccountActions, AccountActionTypes, AccountState, AccountThunk, PropertyTypes, saveUserProperty, setUserPropertyUpdateError, setUserPropertyUpdating, updateEmailStatus, updatePolling, UserProperty } from './types';

const initialState: AccountState = {
    id: null,
    isRefreshing: false,
    pollingId: null,
    isPolling: false,
    name: {
        type: 'name',
        value: null,
        newValue: null,
        isUpdating: false
    },
    firstName: {
        type: 'firstName',
        value: null,
        newValue: null,
        isUpdating: false
    },
    lastName: {
        type: 'lastName',
        value: null,
        newValue: null,
        isUpdating: false
    },
    phone: {
        type: 'phone',
        value: null,
        newValue: null,
        isUpdating: false
    },
    email: {
        type: 'email',
        value: null,
        newValue: null,
        isUpdating: false,
        emailVerified: false,
        sendingVerifyEmail: false,
        sentVerifyEmail: false
    },
    username: null,
    newPassword: null
};

const accountReducer: Reducer<AccountState, AccountActionTypes> = (state: AccountState = initialState, action: AccountActionTypes): AccountState => {
    switch (action.type) {
        case AccountActions.INIT:
            return {
                ...state,
                id: action.payload.id,
                name: makeProperty('name', action.payload.name),
                firstName: makeProperty('firstName', action.payload.firstName),
                lastName: makeProperty('lastName', action.payload.lastName),
                phone: makeProperty('phone', action.payload.phone),
                email: {
                    ...makeProperty('email', action.payload.email),
                    emailVerified: action.payload.emailVerified
                }
            };
        case AccountActions.SET_NAME:
            return {
                ...state,
                name: makeProperty('name', action.payload)
            };
        case AccountActions.SET_FIRST_NAME:
            return {
                ...state,
                firstName: makeProperty('firstName', action.payload)
            };
        case AccountActions.SET_LAST_NAME:
            return {
                ...state,
                lastName: makeProperty('lastName', action.payload)
            };
        case AccountActions.SET_PHONE:
            return {
                ...state,
                phone: makeProperty('phone', action.payload)
            };
        case AccountActions.SET_EMAIL:
            return {
                ...state,
                email: makeProperty('email', action.payload)
            };
        case AccountActions.SET_PASSWORD:
            return state;
        case AccountActions.UPDATE_PROPERTY:
            return {
                ...state,
                [action.payload.type]: action.payload,
            };
        case AccountActions.SET_REFRESHING:
            return {
                ...state,
                isRefreshing: action.payload
            }
        case AccountActions.UPDATE_EMAIL: {
            const newState = {
                ...state
            };

            if (action.payload.isVerified != null) {
                newState.email.emailVerified = action.payload.isVerified;
            }

            if (action.payload.sendingVerifyEmail != null) {
                newState.email.sendingVerifyEmail = action.payload.sendingVerifyEmail;

                // if sendingVerifyEmail is true, then sentVerifyEmail must be false
                if (action.payload.sendingVerifyEmail) {
                    newState.email.sentVerifyEmail = false;
                }
            }

            if (action.payload.sentVerifyEmail != null) {
                newState.email.sentVerifyEmail = action.payload.sentVerifyEmail;
            }

            return newState;
        }
        case AccountActions.UPDATE_POLLING: {
            // if we're stopping polling, make sure to clear the current interval (if it exists)
            if (!action.payload.isPolling) {
                if (state.pollingId != null) {
                    clearInterval(state.pollingId);
                }
            }

            return {
                ...state,
                ...action.payload // isPolling and pollingId
            };
        }
        case AccountActions.RESET: {
            return {
                id: null,
                isRefreshing: false,
                pollingId: null,
                isPolling: false,
                name: {
                    type: 'name',
                    value: null,
                    newValue: null,
                    isUpdating: false
                },
                firstName: {
                    type: 'firstName',
                    value: null,
                    newValue: null,
                    isUpdating: false
                },
                lastName: {
                    type: 'lastName',
                    value: null,
                    newValue: null,
                    isUpdating: false
                },
                phone: {
                    type: 'phone',
                    value: null,
                    newValue: null,
                    isUpdating: false
                },
                email: {
                    type: 'email',
                    value: null,
                    newValue: null,
                    isUpdating: false,
                    emailVerified: false,
                    sendingVerifyEmail: false,
                    sentVerifyEmail: false
                },
                username: null,
                newPassword: null
            };
        }
        default:
            return state;
    }
};

export default accountReducer;

type AccountThunkDispatch = ThunkDispatch<RootState, undefined, AccountActionTypes>;

function makeProperty<T extends PropertyTypes>(type: T, value: string): UserProperty<T> {
    return {
        type,
        value,
        newValue: value,
        isUpdating: false,
        errorUpdating: false
    };
}

export const setEmailStatusPolling = (accessToken: string, userId: string, poll: boolean): AccountThunk<void> => {
    return (dispatch: ThunkDispatch<RootState, undefined, AccountActionTypes>, getState: (() => RootState)) => {
        if (poll) {
            const id = setInterval(() => {

            }, 10000);

            dispatch(updatePolling(true, id));
        } else {
            dispatch(updatePolling(false));
        }
    }
}

export const updateProperty = (getAccessToken: () => Promise<string>, userId: string, property: UserProperty<PropertyTypes>): AccountThunk<void> => {
    return (dispatch: ThunkDispatch<RootState, undefined, AccountActionTypes>, getState: (() => RootState)) => {
        console.log('starting property update...');
        dispatch(setUserPropertyUpdating(property, true));

        //const timeout = setTimeout(() => {
        //	console.log('property update complete...');

        //	let newState = selectAccount(getState());
        //	dispatch(saveUserProperty(newState[property.type]));

        //	newState = selectAccount(getState());
        //	dispatch(setUserPropertyUpdating(newState[property.type], false));
        //	clearTimeout(timeout);
        //}, 5000);

        if (property.type === 'email') {
            // if we're updating email, also show email verification status
            dispatch(updateEmailStatus({ isVerified: false, sendingVerifyEmail: true }));
        }

        //const userUrl = `account/user/${userId}`;
        //fetch(userUrl, {
        //    method: 'get',
        //    headers: {
        //        Authorization: `Bearer ${accessToken}`
        //    }
        //}).then(resp => {
        //    return resp.json();
        //}).then(json => console.log(json));

        const type: PropertyTypes = property.type === 'firstName' || property.type === 'lastName' ? 'name' : property.type;
        let value = property.newValue;
        if (property.type === 'firstName' || property.type === 'lastName') {
            const state = selectAccount(getState());
            value = property.type === 'firstName' 
                ? state.firstName.newValue + ' ' + state.lastName.value 
                : state.firstName.value + ' ' + state.lastName.newValue;
        }

        const url = `account/user/${userId}?${type}=${value}`;
        getAccessToken().then(accessToken => {
            if (accessToken) {
                return fetch(url, {
                    method: 'patch',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
            }

            throw 'Failed Retrieving Access Token';
        }).then((resp) => {
            if (resp.status === 200) {
                dispatch(saveUserProperty(property));
                if (property.type === 'email') {
                    dispatch(updateEmailStatus({
                        sendingVerifyEmail: false,
                        sentVerifyEmail: true
                    }));
                }
            } else {            
                resp.text().then(errorText => console.error(errorText));
                throw 'Failed Updating Property';
            }
        }).catch(error => {
            // handle errors
            dispatch(setUserPropertyUpdateError(property));
            console.error(error);
        }).finally(() => {
            const updatedState = selectAccount(getState());
            dispatch(setUserPropertyUpdating(updatedState[property.type], false));
        });
    };
};

export const sendEmailVerification = (accessToken: string, userId: string): AccountThunk<Promise<boolean>> => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AccountActionTypes>) => {
        if (accessToken && userId) {
            dispatch(updateEmailStatus({ sendingVerifyEmail: true, sentVerifyEmail: false }));

            const resp = await fetch(`account/user/${userId}/verify`, {
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const success = resp.status === 200;

            dispatch(updateEmailStatus({ sendingVerifyEmail: false, sentVerifyEmail: success }));
            return success;
        } else {
            return false;
        }
    };
};

export const checkEmailVerified = (accessToken: string, userId: string): AccountThunk<Promise<boolean>> => {
    return (dispatch: AccountThunkDispatch) => {
        return fetch(`account/user/${userId}/check-verified`, {
            method: "get",
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(resp => {
            if (resp.status === 200)
                return resp.text();
            return Promise.resolve('');
        }).then(text => {
            let isVerified = false;

            if (text) {
                isVerified = !!text;
            }

            dispatch(updateEmailStatus({ isVerified }));

            return isVerified;
        })
    }
};
