import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { JobInfo, ReportOverview, ReportOverviewWithStats } from '../../helpers/types';
import { BaseState, PayloadAction, RootState } from '../types';
import { IDateOnly } from '../../helpers/utils';

export enum FilterActions {
	SetJobs = 'filter/setJobs',
	StartRefresh = 'filter/startRefresh',
	EndRefresh = 'filter/endRefresh',
	SelectJob = "filter/selectJob",
	SelectStart = "filter/selectStartDate",
	SelectEnd = "filter/selectEndDate",

	Reset = 'filter/reset'
}

export interface FilterToolbarState extends BaseState<FilterActions> {
	job: JobInfo | string;
	jobs: JobInfo[];
	recentJobs?: JobInfo[];

	startDate: IDateOnly;
	endDate: IDateOnly;
	refreshing?: boolean;
	searchEnabled?: boolean;
}

export enum HistoryActions {
    ShowReports = 'history/showReports',
    SelectReport = 'history/selectReport',
    SearchReports = 'history/searchReports',

    ShowReportInfo = 'history/reportInfo/show',
	DownloadReportInfo = 'history/reportInfo/download',
	SetReportInfo = 'history/reportInfo/set',

	Reset = 'history/reset'
}

export interface HistoryState extends BaseState<HistoryActions> {
    reports: ReportOverview[];
	queryId: string;
	queryHasMore: boolean;

    selectedReport: ReportOverview;
    isReportSelected: boolean;
    isSearchingReports: boolean;

    showReportInfo: boolean;
	isDownloadingReportInfo?: boolean;
	reportInfo?: ReportOverviewWithStats;
}

export interface ShowReportsAction {
	type: typeof HistoryActions.ShowReports;
	payload: ReportOverview[];
}

export interface SelectReportAction {
	type: typeof HistoryActions.SelectReport;
	payload: ReportOverview;
}

export interface SearchReportsAction {
	type: typeof HistoryActions.SearchReports;
	payload: SearchReportsPayload;
}

export interface SearchReportsPayload {
	isSearching: boolean;
	results?: ReportOverview[];
	queryId?: string;
	isMoreAvailable?: boolean;
}

export interface ShowReportInfoAction extends Action<HistoryActions.ShowReportInfo> {
    payload: boolean;
}

export interface DownloadReportInfoAction extends Action<HistoryActions.DownloadReportInfo> {
	payload: boolean;
}

export interface SetReportInfoAction extends Action<HistoryActions.SetReportInfo> {
	payload: ReportOverviewWithStats;
}

export type ResetHistoryStateAction = Action<typeof HistoryActions.Reset>;

/****** action creator functions ******/
export const selectReport = (report: ReportOverview): SelectReportAction => {
	return {
		type: HistoryActions.SelectReport,
		payload: report
	}
};

export const showReports = (reports: ReportOverview[]): ShowReportsAction => {
	return {
		type: HistoryActions.ShowReports,
		payload: reports
	}
};

export const startSearch = (): SearchReportsAction => {
	return {
		type: HistoryActions.SearchReports,
		payload: {
			isSearching: true
		}
	}
};

export const completeSearch = (reports: ReportOverview[], queryId = '', isMoreAvailable = false): SearchReportsAction => {
	return {
		type: HistoryActions.SearchReports,
		payload: {
			isSearching: false,
			results: reports,
			queryId,
			isMoreAvailable
		}
	};
};

export const showReportInfo = (show: boolean): ShowReportInfoAction => {
    return {
        type: HistoryActions.ShowReportInfo,
        payload: show
    };
};

export const setDownloadingReport = (downloading: boolean): DownloadReportInfoAction => {
    return {
        type: HistoryActions.DownloadReportInfo,
        payload: downloading
    };
};

export const setReportInfo = (report: ReportOverviewWithStats): SetReportInfoAction => {
    return {
		type: HistoryActions.SetReportInfo,
		payload: report
    };
};

export const resetHistoryState = (): ResetHistoryStateAction => ({ type: HistoryActions.Reset });

export type HistoryActionTypes = 
	ShowReportsAction | SelectReportAction | SearchReportsAction | 
	ShowReportInfoAction | DownloadReportInfoAction | SetReportInfoAction |
	ResetHistoryStateAction;

export type HistoryThunk<R> = ThunkAction<R, RootState, undefined, HistoryActionTypes>;
