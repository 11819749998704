
const Colors = {
	primary: "#1976d2",
	primaryDark: "#0d47a1",
	accent: "#f50057",

	grey300: "#e0e0e0",
	grey400: "#bdbdbd",
	grey500: "#9e9e9e",
	grey600: "#757575",
	grey700: "#616161",
	grey800: "#424242",
	grey900: "#212121",

	blue500: "#2196f3",
	blue600: "#1e88e5",
	blue700: "#1976d2",
	blue800: "#1565c0",
	blue900: "#0d47a1",

	red500: "#f44336",
	red600: "#e53935",
	red700: "#d32f2f",
	red800: "#c62828",
	red900: "#b71c1c",

	redA700: "#d50000",
};

export default Colors;