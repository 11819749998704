import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { DataEntry, IDictionary, Range, ReportInfo, RunStats, StopInfo } from '../../helpers/types';
import { BaseState, PayloadAction, RootState } from '../types';
import { createAction } from '@reduxjs/toolkit';

export enum ViewerActions {
	Initialize = 'viewer/init',
	StartLoading = 'viewer/startLoading',

	SetReport = 'viewer/setReport',
	MoveLeft = 'viewer/moveLeft',
	MoveRight = 'viewer/moveRight',
	SetRun = 'viewer/setRun',
	SetRange = 'viewer/setRange',
	SetSensor = 'viewer/setSensor',

	Reset = 'viewer/reset'
}

export interface OverviewStats {
	totalPavingTime?: number,
	pavingTime?: number,
	stopCount?: number,
	slowCount?: number,
	pavingDirection?: 'Increasing' | 'Decreasing'
}

export interface MoveStatus {
	canMoveLeft?: boolean;
	canMoveRight?: boolean;
}

export interface DataState extends DataStats {
	range: number,

	/**
	 * The chainage range for the entire run
	 * */
	chainageRange?: Range,

	/**
	 * The collection of entries for the current selected run
	 * */
	runEntries?: DataEntry[],

	/**
	 * The range of chainages currently being plotted
	 * */
	plotPosition?: Range,
	
	/**
	 * The collection of entries associated with the current plot range
	 * */
	plotEntries?: DataEntry[],

	overviewStats?: OverviewStats,
}

export interface DataStats {
	speedStats?: RunStats;
	tempStats?: RunStats;
	stopStats?: StopInfo[];
	slowdowns?: Range[];
}

export interface ViewerState extends DataState, MoveStatus, PersistedViewerState, BaseState<ViewerActions> {
	isLoading?: boolean,
}

export interface RunState {
	runNumber: number,

	/**
	 * The chainage range for the entire run
	 * */
	chainageRange?: Range,

	/**
	 * The collection of entries for the current selected run
	 * */
	runEntries?: DataEntry[],

	/**
	 * The range of chainages currently being plotted
	 * */
	plotPosition?: Range,
	
	/**
	 * The collection of entries associated with the current plot range
	 * */
	plotEntries?: DataEntry[],
}

/**
 * Interface containing the ViewerState properties which are persisted across navigations.
 * - Only the core, user-specified or server-retrieved values are persisted. The rest of the state can be calculated from these core values
 */
export interface PersistedViewerState {
	report: ReportInfo,
	run: number,
	sensor: number,
	range: number,
	selectedRanges?: IDictionary<number, number>
}

export function extractPersistedViewerState(viewerState: ViewerState): PersistedViewerState {
	return {
		report: viewerState.report,
		run: viewerState.run,
		sensor: viewerState.sensor,
		range: viewerState.range,
		selectedRanges: viewerState.selectedRanges
	};
}

/********** viewer action creators **********/
export const moveLeft = createAction<void, ViewerActions.MoveLeft>(ViewerActions.MoveLeft);
export const moveRight = createAction<void, ViewerActions.MoveRight>(ViewerActions.MoveRight);
export const setRunNumber = createAction<number, ViewerActions.SetRun>(ViewerActions.SetRun);
export const setPlotRange = createAction<number, ViewerActions.SetRange>(ViewerActions.SetRange);
export const setSensorNumber = createAction<number, ViewerActions.SetSensor>(ViewerActions.SetSensor);
export const setReport = createAction<ReportInfo, ViewerActions.SetReport>(ViewerActions.SetReport);
export const startLoading = createAction<void, ViewerActions.StartLoading>(ViewerActions.StartLoading);
export const initializeViewer = createAction<void, ViewerActions.Initialize>(ViewerActions.Initialize);
export const resetViewerState = createAction<void, ViewerActions.Reset>(ViewerActions.Reset);

/********** viewer actions ***********/
type InitializeViewerAction = ReturnType<typeof initializeViewer>
type ResetViewerStateAction = ReturnType<typeof resetViewerState>
type StartLoadingAction = ReturnType<typeof startLoading>
type MoveLeftAction = ReturnType<typeof moveLeft>
type MoveRightAction = ReturnType<typeof moveRight>
type SetReportAction = ReturnType<typeof setReport>
type ChangeRunAction = ReturnType<typeof setRunNumber>
type SetRangeAction = ReturnType<typeof setPlotRange>
type SetSensorAction = ReturnType<typeof setSensorNumber>

export type ViewerActionTypes = InitializeViewerAction | SetReportAction | StartLoadingAction |
	MoveLeftAction | MoveRightAction |
	ChangeRunAction | SetRangeAction | SetSensorAction |
	ResetViewerStateAction;

export type ViewerThunk<R> = ThunkAction<R, RootState, undefined, ViewerActionTypes>;