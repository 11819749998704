import { HistoryState, HistoryActionTypes, HistoryActions, startSearch, completeSearch, HistoryThunk, showReportInfo, setDownloadingReport, setReportInfo } from "./types";
import { customParseJSON, DateOnly, getLayers, getSessionState } from '../../helpers/utils';
import { QueryOptions, ReportOverview, ReportOverviewWithStats, ReportQueryResult } from "../../helpers/types";
import { Reducer, Dispatch } from 'redux';
import { AllActions, ErrorActionTypes, RootState, setErrorMessage } from '../types';

/***** history state interface  *****/
const initialState: HistoryState = {
	reports: getSessionState<ReportOverview[]>('history.reports', null),
	queryId: null,
	queryHasMore: false,

	selectedReport: null,
	isReportSelected: false,
	isSearchingReports: false,

	showReportInfo: false,
	reportInfo: null,
	isDownloadingReportInfo: false
};

/**
 * history reducer function
 * @param state	the current state before executing the current action
 * @param action the current action to execute
 */
const historyReducer: Reducer<HistoryState, HistoryActionTypes> = (state: HistoryState = initialState, action: HistoryActionTypes): HistoryState => {
	switch (action.type) {
		case HistoryActions.ShowReports: {

            for (const report of action.payload) {
				report.overview.layer = getLayers(report);
            }

			return {
				...state,
				reports: action.payload
			};
		}
		case HistoryActions.SelectReport: {
			return {
				...state,
				selectedReport: action.payload,
				isReportSelected: action.payload != null
			};
		}
		case HistoryActions.SearchReports:
			return {
				...state,
				selectedReport: null,
				showReportInfo: false,
				reports: action.payload.isSearching ? null : action.payload.results,
				queryId: action.payload.isSearching ? '' : action.payload.queryId,
				queryHasMore: action.payload.isSearching ? false : action.payload.isMoreAvailable,
				isReportSelected: false,
				isSearchingReports: action.payload.isSearching
			};
		case HistoryActions.ShowReportInfo:
			return {
				...state,
				showReportInfo: action.payload
			};
		case HistoryActions.SetReportInfo:
            return {
				...state,
				reportInfo: action.payload
            };
		case HistoryActions.DownloadReportInfo:
            return {
				...state,
				isDownloadingReportInfo: action.payload
			};
		case HistoryActions.Reset: {
			return {
				...state,
				reports: null,
				selectedReport: null,
				queryId: null,
				queryHasMore: null
			};
		}
		default:
			return state;
	}
};

/***** thunk functions *****/
export const fetchReports = (getAccessToken: () => Promise<string>, queryOpts: QueryOptions): HistoryThunk<void> => {
	return (dispatch: Dispatch<AllActions>, _getState: (() => RootState)) => {
		dispatch(startSearch());

		const query = new URLSearchParams({ overviews: 'true' });
		if (queryOpts.startDate) {
			query.set('start', queryOpts.startDate.toStringJSON());
		}

		if (queryOpts.endDate) {
			query.set('end', queryOpts.endDate.toStringJSON());
		}

		//query.set('overviews', 'true');

		getAccessToken().then(accessToken => {
			if (accessToken) {
				return fetch(`/reports/${queryOpts.jobId}?${query.toString()}`, {
					method: 'GET',
					headers: new Headers([
						['Authorization', `Bearer ${accessToken}`]
					])
				});
			}

			throw 'Failed Retrieving Access Token';
		}).then(response => {
			if (response?.status === 200) {
				return response.text();
			} else if (response.status === 403) {
				console.log('Forbidden response for reports query');

				if (response.headers.get('x-paveset-no-permission')) {
					dispatch(setErrorMessage('You Do Not Have Permission to View This Job!'));
				} else {
					dispatch(setErrorMessage('Invalid Login Details. Try Refreshing the Page or Logging-Out then Back In'));
				}
			}

			throw `Invalid Response from Server: ${response?.status}`;
		}).then(body => {
			const data = body ? customParseJSON(body) as ReportQueryResult : null;

			for (const rep of data?.reports ?? []) {
				rep.created = <Date>(rep.created || 0);
			}

			if (data) {
				dispatch(completeSearch(data.reports.sort(compareReports), data.queryId, !!data.continuationToken));
            } else {
				dispatch(completeSearch([]));
			}			
		}).catch(reason => {
			console.error(reason);
			dispatch(setErrorMessage('Error Encountered While Querying Reports: ' + reason));
		});
	}
};

function compareReports(x: ReportOverview, y: ReportOverview): number {
	if (x.created && y.created) {
		return x.created > y.created ? 1 : -1;
	}

	if (x.overview.date > y.overview.date) {
		return 1;
    } else if (x.overview.date < y.overview.date) {
		return -1;
    }

    if (x.overview.layer > y.overview.layer) return 1;
    if (x.overview.layer < y.overview.layer) return -1;
	return 0;
}

export const showReportStats = (getAccessToken: () => Promise<string>, jobId: string, id: string): HistoryThunk<Promise<ReportOverviewWithStats>> => {
    return async (dispatch: Dispatch<HistoryActionTypes | ErrorActionTypes>, getState: (() => RootState)) => {
		const state = getState().history;

        if (state.reportInfo && state.reportInfo.id === id) {
			// we're showing info for the same report, so just show the cached values from the state
			dispatch(showReportInfo(true));			// display the report info popup
			dispatch(setDownloadingReport(false));	// make sure the downloading flag is false (it should be already)

			return Promise.resolve(state.reportInfo); // return the report stats from the state
        }

		dispatch(setDownloadingReport(true));
		dispatch(showReportInfo(true));

		const accessToken = await getAccessToken();

		const resp = await fetch(`/reports/${jobId}/${id}?statsOnly=true`, {
			method: 'get',
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});

		dispatch(setDownloadingReport(false));

		if (resp?.status === 200) {
			const body = await resp.text();
			const data = body ? customParseJSON(body) as ReportOverviewWithStats : null;

			dispatch(setReportInfo(data));

			if (!data) {
				dispatch(setErrorMessage('Invalid Data Returned by Server'));
			}

			return data;
		} else {
			dispatch(setReportInfo(null));
			dispatch(setErrorMessage(`Invalid Response from Server: ${resp.statusText} (${resp?.status})`));
		}

		return null;
    };
}

export default historyReducer;