import { createTheme } from '@material-ui/core/styles';

const customTheme = createTheme({
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Montserrat',
			'Roboto',
			'"Segoe UI"',
			'sans-serif'
		],
		fontWeightBold: 700,
		fontWeightRegular: 400,
		fontWeightLight: 300,
		body1: {
			fontWeight: 400
		},

		h5: {
			fontWeight: 500
		},
		h6: {
			fontWeight: 500
		}
	},
	palette: {
		//primary: {
		//	main: '#1976d2',
		//	light: '#4791db',
		//	dark: '#115293'
		//},
		blue: {
			main: '#1976d2',
			light: '#4791db',
			dark: '#115293'
		},
		error: {
			main: '#d50000',
			light: '#f44336',
			dark: '#c62828'
		},
		gray: {
			100: '#F5F5F5',
			200: '#EEEEEE',
			300: '#E0E0E0',
			400: '#BDBDBD',
			500: '#9E9E9E',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121'
		}
	}
});

export { customTheme }
