import { Middleware, MiddlewareAPI } from 'redux';
import { AllActions, RootState, SharedActions } from './types';
import { customStringifyJSON } from '../helpers/utils';
import { FilterActions, HistoryActions } from "./history/types";
import { extractPersistedViewerState, ViewerActions, ViewerState } from './viewer/types';
import { AccountActions } from './account/types';
import { ThunkDispatch } from 'redux-thunk';

const persistFilterActions = [
	FilterActions.EndRefresh,
	FilterActions.SelectJob,
	FilterActions.SelectStart,
	FilterActions.SelectEnd
];

const persistViewerActions = [
	ViewerActions.SetRun,
	ViewerActions.SetRange,
	ViewerActions.SetSensor,
	ViewerActions.SetReport
];

const resetActions = [
	ViewerActions.Reset,
	FilterActions.Reset,
	HistoryActions.Reset,
	AccountActions.RESET
];


export const persistStoreMiddleware: Middleware<unknown, RootState, ThunkDispatch<RootState, undefined, AllActions>> =
	(store: MiddlewareAPI<ThunkDispatch<RootState, undefined, AllActions>>) =>
		(next: ThunkDispatch<RootState, undefined, AllActions>) =>
			(action: AllActions) => {
				if (action.type === HistoryActions.SearchReports) {
					const result = next(action);
					const state = store.getState() as RootState;

					if (state.history.isSearchingReports) {
						sessionStorage.removeItem('history.reports');
					} else {
						sessionStorage.setItem('history.reports', customStringifyJSON(state.history.reports));
					}

					return result;
				} else if (persistFilterActions.contains(<FilterActions>action.type)) {
					const result = next(action);
					const state = store.getState() as RootState;
					const { job, jobs, recentJobs, startDate, endDate } = state.filterToolbar;

					sessionStorage.setItem('filterState', customStringifyJSON({ job, jobs, recentJobs, startDate, endDate }));
					return result;
				} else if (persistViewerActions.contains(<ViewerActions>action.type)) {
					const result = next(action);
					const state = extractPersistedViewerState(store.getState().viewer);

					sessionStorage.setItem('viewerState', customStringifyJSON(state));

					return result;
				} else if (action.type == FilterActions.Reset) {
					sessionStorage.removeItem('filterState');
					return next(action);
				} else if (action.type == HistoryActions.Reset) {
					sessionStorage.removeItem('history.reports');
					return next(action);
				} else if (action.type == ViewerActions.Reset) {
					sessionStorage.removeItem('viewerState');
					return next(action);
				} else if (action.type == AccountActions.RESET) {
					return next(action);
				} else {
					return next(action);
				}
			};