import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * Hook to create a function to retrieve an access token
 * @returns a function that can be used to retrieve the current access token
 */
export const useGetAccessToken = (): (() => Promise<string>) => {
	const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getAccessToken = useCallback(() => {
        if (isAuthenticated) {
            return getAccessTokenSilently();
        }
        return null;
    }, [getAccessTokenSilently, isAuthenticated]);

	return getAccessToken;
}

export default useGetAccessToken;