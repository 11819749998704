import { DataEntry, IDictionary, Range, ReportInfo, RunInfo, RunStats, StopInfo } from '../../helpers/types';
import { customParseJSON, getDataEntries, getMaxRange, getRunDataEntries, getSessionState, ReportQuery } from '../../helpers/utils';
import { DataState, DataStats, initializeViewer, moveLeft, moveRight, MoveStatus, OverviewStats, resetViewerState, RunState, setPlotRange, setReport, setRunNumber, setSensorNumber, startLoading, ViewerActions, ViewerActionTypes, ViewerState, ViewerThunk } from './types';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState, setErrorMessage, ErrorActionTypes } from '../types';
import { countStops, findStops, getRun, initializeRunDirections } from '../../helpers/reportHelpers';
import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import PaverPositionProcessor from '../../helpers/PaverPositionProcessor';

const persistedState = getSessionState<ViewerState>('viewerState', {
	report: null,
	range: 100,
	run: -1,
	sensor: 1,
	selectedRanges: {}
});

const emptyState: ViewerState = {
	report: null,
	range: 100,
	run: -1,
	sensor: 1,

	//runs: null,
	//selectedRun: null,

	runEntries: null,
	plotEntries: null,
	chainageRange: null,
	plotPosition: null,

	canMoveLeft: false,
	canMoveRight: false,

	speedStats: null,
	tempStats: null,
	stopStats: null,
	isLoading: false,
	overviewStats: null,

	slowdowns: null,

	selectedRanges: {}
};

const initialState: ViewerState = {
	...emptyState,
	...persistedState,
	isLoading: true
};

interface FetchReportArgs {
	getAccessToken: () => Promise<string>;
	jobId: string;
	id: string;

	processWidths?: boolean;
}

/********** Viewer Thunks ********************************/
const fetchReport = createAsyncThunk(
	'viewer/fetchReport',
	async (args: FetchReportArgs, thunkApi) => {
		const { getAccessToken, jobId, id, processWidths } = args;
		const { dispatch, getState, signal } = thunkApi;
		const { viewer } = getState() as RootState;

		if (!viewer.isLoading) {
			dispatch(startLoading());
		}

		let accessToken = null;
		try {
			accessToken = await getAccessToken();
		} catch (error) {
			console.error(error);
			throw 'Failed Retrieving Access Token'; // throw error to cancel thunk
		}

		try {
			const response = await fetch(`/reports/${encodeURIComponent(jobId)}/${encodeURIComponent(id)}`, {
				headers: { Authorization: `Bearer ${accessToken}` },
				method: 'GET'
			});

			if (response.status !== 200) {
				if (response.status === 403) {
					// Forbidden response
					console.log('forbidden to access report');
					if (response.headers.get('x-paveset-no-permission')) {
						dispatch(setErrorMessage('You Do Not Have Permission to View This Report'));
					} else {
						dispatch(setErrorMessage('Report Download was Forbidden. Try Refreshing the Page or Logging-Out then Back In'));
					}
				} else if (response.status === 404) {
					// NotFound response
					dispatch(setErrorMessage('Report Was Not Found'));
				}

				return;
			}

			const body = await response.text();
			if (!body) {
				dispatch(setReport(null));
				return;
			}

			const report = customParseJSON<ReportInfo>(body);
			
			if (processWidths) {
				// TODO: extract proper offsets from the report itself eventually
				const processor = new PaverPositionProcessor(report, -0.4, 0.0, 0.0);
				processor.processReport();
			}

			initializeRunDirections(report);
			dispatch(setReport(report));
		} catch (error) {
			console.log(`Error Fetching Reports: ${error}`);
			if (error instanceof Error) {
				console.error(error.message);
				console.info(error.cause);
				console.info(error.stack);
			} else {
				console.trace();			
			}
			

			if (typeof error === 'string') {
				dispatch(setErrorMessage(error));
			} else if (error instanceof Error) {
				dispatch(setErrorMessage(error.message));
			}

			dispatch(setReport(null));
		}
	}
);

const viewerStartup = (getAccessToken: () => Promise<string>, queryOpts: ReportQuery): ViewerThunk<void> => {
	return (dispatch: ThunkDispatch<RootState, undefined, ViewerActionTypes>) => {
		if (queryOpts) {
			dispatch(fetchReport({ getAccessToken, jobId: queryOpts.jobId, id: queryOpts.id, processWidths: queryOpts.processWidths }));
		} else {
			dispatch(initializeViewer());
		}
	};
};

const viewerReducer = createReducer(initialState, builder => {
	builder
		.addCase(startLoading, (state, _action) => {
			state = {
				...state,
				...emptyState,
				isLoading: true
			};
		})
		.addCase(initializeViewer, (state, _action) => {
			if (state.report) {
				if (state.run > 0) {
					const dataState = initDataState(state.report, state.run, state.range, state.sensor);
					// const runs = initRunsState(state.report, state.range, state.sensor, state.selectedRanges);
					// const selectedRun = runs.find(r => r.runNumber == state.run);
					
					state = {
						...state,
						...dataState,
						...checkMoveStatus(dataState.plotPosition, dataState.chainageRange),

						isLoading: false
					};
				} else {
					state.isLoading = false;
					state.canMoveLeft = false;
					state.canMoveRight = false;
				}
			}
		})
		.addCase(setReport, (state, action) => {
			if (action.payload) {
				return {
					...state,
					report: action.payload,
					run: -1,
					sensor: 1,
					range: 100,
					selectedRanges: {},
					...getNoRunState(),
					overviewStats: calcOverviewStats(action.payload, null),
					isLoading: false
				};
			} else {
				return {
					...state,
					...emptyState
				};
			}
		})
		.addCase(setRunNumber, (state, action) => {
			const run = action.payload;

			// check if we have a report selected
			if (state.report) {
				// check if we are selecting a valid run number (not selecting 'none')
				if (run) {
					const dataState = initDataState(state.report, run, state.range, state.sensor, state.selectedRanges);
					
					const selectedRanges = { ...state.selectedRanges } as IDictionary<number, number>;
					selectedRanges[run] = dataState.range; // add or update selected range for this run

					return {
						...state,
						run,
						selectedRanges,
						...dataState,
						...checkMoveStatus(dataState.plotPosition, dataState.chainageRange)
					} as ViewerState;
				} else {
					return {
						...state,
						run,
						...getNoRunState()
					};
				}
			} else {
				return {
					...state,
					run
				};
			}
		})
		.addCase(setPlotRange, (state, action) => {
			const range = action.payload;

			if (state.report && state.run > 0) {
				const dataState = initDataState(state.report, state.run, range, state.sensor);
				const selectedRanges = { ...state.selectedRanges } as IDictionary<number, number>;
				selectedRanges[state.run] = dataState.range;

				return {
					...state,
					range,
					selectedRanges,
					...dataState,
					...checkMoveStatus(dataState.plotPosition, dataState.chainageRange)
				};
			}
			return {
				...state,
				range
			};
		})
		.addCase(setSensorNumber, (state, action) => {
			const sensor = action.payload;
			const stats = calcStats(state.runEntries, sensor);

			Object.assign(state, { ...stats, sensor });
		})
		.addCase(moveLeft, (state, _action) => {
			if (!state.canMoveLeft)
				return state;

			const runEnd = state.runEntries.last().ch;
			const runStart = state.runEntries.first().ch;
			const dir = getRun(state.report, state.run).direction;

			const wasAtEnd = dir === 'Increasing' ? state.plotPosition.end >= runEnd : state.plotPosition.end <= runEnd;
			const start = dir === 'Increasing' ? Math.max(state.plotPosition.start - state.range, runStart) : Math.min(state.plotPosition.start + state.range, runStart);
			const clippedToStart = dir === 'Increasing' ? start > state.plotPosition.start - state.range : start < state.plotPosition.start + state.range;

			const plotPosition = {
				start: start,
				end: dir === 'Increasing' ? start + state.range : start - state.range,
				direction: dir
			};

			if (wasAtEnd && !clippedToStart) {
				const diff = plotPosition.start % state.range;

				if (diff !== 0) {
					const adj = state.range - diff;

					if (dir === 'Increasing') {
						plotPosition.start += adj;
						plotPosition.end += adj;
					} else {
						plotPosition.start -= adj;
						plotPosition.end -= adj;
					}
				}
			}

			const plotEntries = getPlotEntries(state.runEntries, plotPosition);
			const moveStatus = checkMoveStatus(plotPosition, state.chainageRange);
			Object.assign(state, { ...moveStatus, plotPosition, plotEntries });
			// state.plotPosition = plotPosition;
			// state.plotEntries = getPlotEntries(state.runEntries, plotPosition);
			// state.canMoveLeft = moveStatus.canMoveLeft;
			// state.canMoveRight = moveStatus.canMoveRight;
		})
		.addCase(moveRight, (state, _action) => {
			if (!state.canMoveRight)
				return state;

			const dir = getRun(state.report, state.run).direction;

			const wasAtStart = dir === 'Increasing'
				? state.plotPosition.start <= state.chainageRange.start
				: state.plotPosition.start >= state.chainageRange.start;
			
			const end = dir === 'Increasing'
				? Math.min(state.chainageRange.end, state.plotPosition.end + state.range)
				: Math.max(state.chainageRange.end, state.plotPosition.end - state.range);

			const clippedToEnd = dir === 'Increasing'
				? end < state.plotPosition.end + state.range
				: end > state.plotPosition.end - state.range;

			const position = {
				start: dir === 'Increasing' ? end - state.range : end + state.range,
				end: end,
				direction: dir
			};

			if (wasAtStart && !clippedToEnd) {
				const diff = position.end % state.range;

				if (diff % state.range !== 0) {
					if (dir === 'Increasing') {
						position.start -= diff;
						position.end -= diff;
					} else {
						position.start += diff;
						position.end += diff;
					}
				}
			}

			const moveStatus = checkMoveStatus(position, state.chainageRange);
			state.plotPosition = position;
			state.plotEntries = getPlotEntries(state.runEntries, position);
			state.canMoveLeft = moveStatus.canMoveLeft;
			state.canMoveRight = moveStatus.canMoveRight;
		})
		.addCase(resetViewerState, (state, _action) => {
			state.report = state.run = state.runEntries = state.plotEntries = null;
		})
		.addCase(fetchReport.pending, (state, action) => {
			console.log('viewer/fetchReports/pending...');
		})
		.addCase(fetchReport.fulfilled, (state, action) => {
			console.log('viewer/fetchReports/fulfilled...');
		})
		.addCase(fetchReport.rejected, (state, action) => {
			console.log('viewer/fetchReports/rejected...');
		})
});

/*********************************************************/
/********** HELPER FUNCTIONS *****************************/
/*********************************************************/
function getNoRunState() {
	return {
		runEntries: null,
		plotEntries: null,
		chainageRange: null,
		plotPosition: null,
		canMoveLeft: false,
		canMoveRight: false
	};
}

function checkMoveStatus(plotPosition: Range, chainageRange: Range): MoveStatus {
	let canMoveLeft = false;
	let canMoveRight = false;

	if (plotPosition && chainageRange) {
		if (chainageRange.direction === 'Increasing') {
			if (plotPosition.start > chainageRange.start) {
				canMoveLeft = true;

				if (plotPosition.end >= chainageRange.end) {
					canMoveRight = false;
				}
			}

			if (plotPosition.end < chainageRange.end) {
				canMoveRight = true;

				if (plotPosition.start <= chainageRange.start) {
					canMoveLeft = false;
				}
			}
		} else {
			if (plotPosition.start < chainageRange.start) {
				canMoveLeft = true;

				if (plotPosition.end <= chainageRange.end) {
					canMoveRight = false;
				}
			}

			if (plotPosition.end > chainageRange.end) {
				canMoveRight = true;

				if (plotPosition.start >= chainageRange.start) {
					canMoveLeft = false;
				}
			}
		}
	} else {
		canMoveLeft = false;
		canMoveRight = false;
	}

	return {
		canMoveLeft,
		canMoveRight
	};
}

function getPlotEntries(run: DataEntry[], position: Range): DataEntry[] {
	if (position.direction === 'Increasing') {
		return run
			.where(e => e.ch >= position.start && e.ch < position.end)
			.toArray();
	} else {
		return run
			.where(e => e.ch <= position.start && e.ch > position.end)
			.toArray();
	}
}

/*
 * creates an initial set of state property values for when certain settings change which require re-calculating the plot data, such as:
 *	- the report, run number, plot range, sensor number (i.e. if any one of the input parameters for this function have changed, we re-initialize)
 */
function initDataState(report: ReportInfo, run: number, range: number, sensor: number, selectedRanges?: IDictionary<number, number>): DataState {
	const runInfo = getRun(report, run);
	const runEntries = getRunDataEntries(runInfo);
	const maxRange = getMaxRange(runEntries);
	let newRange = range > maxRange ? maxRange : range;

	// if previous value for range exists for this run, use it to set the current range
    if (selectedRanges && selectedRanges[run]) {
		newRange = selectedRanges[run];
    }

	const start: number = runInfo.sections.first().startChainage;
	const end: number = runInfo.sections.last().endChainage;

	// const chainageRange: Range = {
	// 	start: start < end ? start : end,
	// 	end: start < end ? end : start
	// };

	const chainageRange: Range = {
		start,
		end,
		direction: runInfo.direction
	};

	const position: Range = {
		start: chainageRange.start,
		end: runInfo.direction === 'Increasing'
			? Math.min(chainageRange.end, chainageRange.start + newRange)
			: Math.max(chainageRange.end, chainageRange.start - newRange),
		direction: runInfo.direction
	};

	return {
		range: newRange,
		chainageRange: chainageRange,
		runEntries: runEntries,
		plotPosition: position,
		plotEntries: getPlotEntries(runEntries, position),
		...calcStats(runEntries, sensor),
		overviewStats: calcOverviewStats(report, runEntries)
	};
}

function initRunsState(report: ReportInfo, range: number, sensor: number, selectedRanges?: IDictionary<number, number>): RunState[] {
	const runs: RunState[] = [];

    for (const run of report.runs) {
		let newRange = range;
        if (selectedRanges && selectedRanges[run.runNumber]) {
			newRange = selectedRanges[run.runNumber];
        }
		runs.push(initRunState(report, run, newRange, sensor));
    }

	return runs;
}

function initRunState(report: ReportInfo, runInfo: RunInfo, range: number, sensor: number): RunState {
	const runEntries = getDataEntries(report, runInfo.runNumber);
	
	const maxRange = getMaxRange(runEntries);
	const newRange = range > maxRange ? maxRange : range;

	const start: number = runInfo.sections.first().startChainage;
	const end: number = runInfo.sections.last().endChainage;

	// const chainageRange: Range = {
	// 	start: start < end ? start : end,
	// 	end: start < end ? end : start
	// };

	const chainageRange: Range = {
		start,
		end,
		direction: runInfo.direction
	};

	const plotPosition: Range = {
		start: chainageRange.start,
		end: runInfo.direction === 'Increasing'
			? Math.min(chainageRange.end, chainageRange.start + newRange)
			: Math.max(chainageRange.end, chainageRange.start - newRange),
		direction: runInfo.direction
	};

	const plotEntries = getPlotEntries(runEntries, plotPosition);

    return {
		runNumber: runInfo.runNumber,
		chainageRange,
		plotPosition,
		runEntries,
		plotEntries
    }
}

// function updatePlotPosition(runs: RunState[], runNumber: number, position: Range): RunState[] {
// 	const newRuns: RunState[] = [];
//     for (const run of runs) {
// 		if (run.runNumber === runNumber) {
//             newRuns.push({
// 				...run,
// 				plotPosition: position,
// 				plotEntries: getPlotEntries(run.runEntries, position)
//             });
//         } else {
// 			newRuns.push(run);
//         }
//     }

// 	return newRuns;
// }

// function updatePlotRange(runs: RunState[], runNumber: number, range: number): RunState[] {
// 	const newRuns: RunState[] = [];
//     for (const run of runs) {
// 		if (run.runNumber === runNumber) {
// 			const position: Range = {
// 				start: run.chainageRange.start,
// 				end: Math.min(run.chainageRange.end, run.chainageRange.start + range)
// 			};

//             newRuns.push({
// 				...run,
// 				plotPosition: position,
// 				plotEntries: getPlotEntries(run.runEntries, position)
//             });
//         } else {
// 			newRuns.push(run);
//         }
//     }

// 	return newRuns;

// }

function calcStats(runEntries: DataEntry[], sensor: number): DataStats {
	const length = runEntries.length;
	const start = Math.trunc(0.05 * length);
	const end = Math.trunc(0.95 * length);
	const slice = runEntries.slice(start, end);

	return {
		speedStats: slice.where(e => !!e.spd).calculateStats(e => e.spd),
		tempStats: slice.where(e => !!e.temps).calculateStats(e => e.temps[sensor - 1]),
		stopStats: findStops(runEntries)
	};
}

function calcOverviewStats(report: ReportInfo, runEntries?: DataEntry[]): OverviewStats {	
	let totalPavingTime = 0;
    for (const run of report.runs) {
        for (const section of run.sections) {
			const start = section.entries.min(e => e.ts.getTime());
			const end = section.entries.max(e => e.ts.getTime());

			totalPavingTime += (end - start) / (1000 * 60);
        }
    }

	// if run as been selected, runEntries will have values so we do all the run-related stats calcs
	if (runEntries) {
		const startEntry = runEntries.minBy(e => e.ts.getTime());
		const endEntry = runEntries.maxBy(e => e.ts.getTime());

		const pavingTime = (endEntry.ts.getTime() - startEntry.ts.getTime()) / (1000.0 * 60);	
		const stopCount = countStops(runEntries);
		const slowCount: number = null;
		const pavingDirection = endEntry.ch > startEntry.ch ? 'Increasing' : 'Decreasing';
		
		return {
			pavingTime,
			totalPavingTime,
			slowCount,
			stopCount,
			pavingDirection
		};
	} else {
		// if runEntries is null, only return the total paving time stat
		return { totalPavingTime };
	}
}

/********** END HELPER FUNCTIONS *************************/

export { fetchReport, viewerStartup };
export default viewerReducer;