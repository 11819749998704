import { Action } from 'redux';
import { FilterActions, FilterToolbarState, HistoryActions, HistoryActionTypes, HistoryState, resetHistoryState } from './history/types';
import { resetViewerState, ViewerActions, ViewerActionTypes, ViewerState } from './viewer/types';
import { AccountActions, AccountActionTypes, AccountState, resetAccountState } from './account/types';
import { UploaderActions } from './uploader/types';
import { Reducer } from 'react';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { resetFilter, FilterActionTypes } from './history/filterToolbarSlice';

export enum SharedActions {
	USER_LOGGED_OUT = 'USER_LOGGED_OUT',
	USER_LOGGED_IN = 'USER_LOGGED_IN'
}

export type UserLoggedOutAction = Action<typeof SharedActions.USER_LOGGED_OUT>;

export type SharedActionTypes = UserLoggedOutAction;

const resetState = (dispatch: ThunkDispatch<RootState, undefined, AllActions>) => {		
	dispatch(resetFilter());
	dispatch(resetHistoryState());
	dispatch(resetViewerState());
	dispatch(resetAccountState());	
};

export const userLoggedOut = (): ThunkAction<void, RootState, undefined, AllActions> => {
	return (dispatch: ThunkDispatch<RootState, undefined, AllActions>) => {
		console.log('Running User Logout Thunk');
		resetState(dispatch);
		dispatch({ type: SharedActions.USER_LOGGED_OUT });
	};
};

export const userLoggedIn = (getAccessToken: () => Promise<string>): ThunkAction<void, RootState, undefined, AllActions> => {
	return async (dispatch: ThunkDispatch<RootState, undefined, AllActions>) => {
		console.log('Running User Login Thunk - Warmup APIs');
		let accessToken = null;
		try {
			accessToken = await getAccessToken();
		} catch (error) {
			console.error(error);
			throw 'Failed Retrieving Access Token'; // throw error to cancel thunk
		}

		try {
			// warmup the website/API by retrieving the list of jobs
			const response = await fetch('/reports/jobs-list?onlyId=true', {
				headers: { Authorization: `Bearer ${accessToken}` },
				method: 'GET'
			});

			if (response.status !== 200) {
				console.log('Failed to retrieve jobs list');
			}
		} catch (error) {
			console.error(error);
			throw 'Failed Retrieving Report'; // throw error to cancel thunk
		}

		console.log('Finished Warming Up APIs');
	};
};

export enum BaseActions {
	RESET = 'RESET_STATE'
}

export enum ServiceWorkerActions {
	SW_INIT = "SW_INIT",
	SW_UPDATE = "SW_UPDATE",
	SW_SETREG = "SW_SETREG"
}

export type AllActionTypes = SharedActions | ErrorActions | HistoryActions | ViewerActions | FilterActions | ServiceWorkerActions | AccountActions | UploaderActions;

export interface PayloadAction<T extends AllActionTypes, P> extends Action<T> {
	payload: P;
}

/**
 * Core interface which all component state interfaces will extend.
 * - Contains a single property to track the last **Action** executed against the state
 */
export interface BaseState<T extends AllActionTypes> {
	latestAction?: T;
}
interface InitAction extends Action<typeof ServiceWorkerActions.SW_INIT> {
	payload?: void;
}

interface UpdateAction extends Action<typeof ServiceWorkerActions.SW_UPDATE> {
	payload: ServiceWorkerRegistration;
}

interface SetRegistrationAction extends Action<typeof ServiceWorkerActions.SW_SETREG> {
	payload: ServiceWorkerRegistration;
}

export interface ServiceWorkerState extends BaseState<ServiceWorkerActions> {
	serviceWorkerInitialized: boolean;
	serviceWorkerUpdated: boolean;
	serviceWorkerRegistration?: ServiceWorkerRegistration;
}

export const initialize = (): InitAction => ({ type: ServiceWorkerActions.SW_INIT });
export const update = (registration: ServiceWorkerRegistration): UpdateAction => ({ type: ServiceWorkerActions.SW_UPDATE, payload: registration });

export type ServiceWorkerActionTypes = InitAction | UpdateAction | SetRegistrationAction;

export interface AuthState {
	accessToken?: string;
	refreshToken?: string;
	isLoggedIn?: boolean;
}

export interface ErrorState {
	errorMessage?: string;
}

export enum ErrorActions {
	SET_MESSAGE = "ERROR_SET_MSG"
}

export type SetErrorMsgAction = PayloadAction<ErrorActions.SET_MESSAGE, string>;
export type ErrorActionTypes = SetErrorMsgAction;
export const setErrorMessage = (errorMsg: string): SetErrorMsgAction => ({ type: ErrorActions.SET_MESSAGE, payload: errorMsg });

export const errorReducer: Reducer<ErrorState, ErrorActionTypes> = (state: ErrorState = { errorMessage: null }, action: ErrorActionTypes): ErrorState => {
	switch (action.type) {
		case ErrorActions.SET_MESSAGE:
			return {
				...state,
				errorMessage: action.payload
			};
		default:
			return state;
	}
}

//export type RootState = ReturnType<typeof rootReducer>;
export type RootState = {
	filterToolbar: FilterToolbarState,
	history: HistoryState,
	viewer: ViewerState,
	serviceWorker: ServiceWorkerState,
	account: AccountState,

	error?: ErrorState,
	auth?: AuthState
}

export type AllActions = SharedActionTypes | ErrorActionTypes | HistoryActionTypes | ServiceWorkerActionTypes | FilterActionTypes | ViewerActionTypes | AccountActionTypes;

export type ReporterThunk<R> = ThunkAction<R, RootState, undefined, AllActions>;