import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";

const useStyles = props => makeStyles((theme) => ({
	root: {
		marginTop: "50px",
		width: '100%',
		//"--sk-color": props.color
	},
	div: {
		//"--sk-size": `${props.size}px`,
		width: props.size,
		height: props.size
	},
	inner: {
		'&::before': {
			backgroundColor: `${props.color}!important`
		}
	},
	cubeInner: {
		backgroundColor: props.color
	}
}));

type LoaderType = "circle" | "chase" | "grid";

export type LoaderProps = {
	type?: LoaderType,
	centered?: boolean,
	color?: string,
	size?: number,
	text?: string,
	style?: React.CSSProperties,
	delayed?: boolean
};

const loaderDefaults: LoaderProps = {
	type: 'circle',
	centered: true,
	color: '#1565c0',
	size: 40,
	text: '',
	style: {},
	delayed: false
};

/**
 * Loader component for displaying various different spinners (from spinkit css)
 * @param props the props for the loader (initialized to loaderDefaults if no props are passed in)
 */
function Loader(props: LoaderProps = loaderDefaults): ReactElement {

	// if props is not null, then merge props with loaderDefaults, overwriting any defaults contained in props
	const { type, centered, color, size, text, style, delayed } = {
		...loaderDefaults,
		...props
	};

	const classes = useStyles({ size, color })();

	const centerClass = centered ? 'sk-center' : '';

	const ref = useRef(null);
	const [display, setDisplay] = useState(true);

	useEffect(() => {
		if (delayed) {
			console.log('delayed loader');
			setDisplay(false);
			const timeout = setTimeout(() => {
				clearTimeout(timeout);

				if (ref.current) {
					setDisplay(true);
				}
			}, 250);
		}

		return () => {
			ref.current = null;
		};

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const circle = () => (
		<div className={`${centerClass} sk-circle ${classes.div}`}>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
			<div className={`${classes.inner} sk-circle-dot`}></div>
		</div>
	);

	const chase = () => (
		<div className={`${centerClass} sk-chase ${classes.div}`}>
			<div className={`${classes.inner} sk-chase-dot`}></div>
			<div className={`${classes.inner} sk-chase-dot`}></div>
			<div className={`${classes.inner} sk-chase-dot`}></div>
			<div className={`${classes.inner} sk-chase-dot`}></div>
			<div className={`${classes.inner} sk-chase-dot`}></div>
			<div className={`${classes.inner} sk-chase-dot`}></div>
		</div>
	);

	const grid = () => (
		<div className={`${centerClass} sk-grid ${classes.div}`}>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
			<div className={`${classes.cubeInner} sk-grid-cube`}></div>
		</div>
	);

	const getSpinner = () => {
		switch (type) {
			case 'circle':
				return circle();
			case 'chase':
				return chase();
			case 'grid':
				return grid();
			default:
				return circle();
		}
	};

	return (
		<div ref={r => ref.current = r}>
			{
				display &&
				<div className={classes.root} style={style} >
					<Grid container direction="row">
						<Grid
							item
							container
							xs={12}>
							{getSpinner()}
						</Grid>

						<Grid
							style={{ marginTop: "2rem" }}
							item
							container
								justifyContent="center"
							alignItems="center"
							xs={12}>
							<label>{text}</label>
						</Grid>
					</Grid>
				</div>
			}
		</div>
	);
}

export default Loader;