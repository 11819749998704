import proj4 from 'proj4';
import { Coord, DataEntry, ReportInfo, WidthsInfo } from './types'
import { Vector, Point } from './vector';

class PaverPositionProcessor {
    private report: ReportInfo;
    private transform: proj4.Converter;

    private leftExtBox: number;
    private rightExtBox: number;
    private centreOffset: number;

    private shouldProcess: boolean;

    constructor(report: ReportInfo, centreOffset: number, leftExtBox = 0, rightExtBox = 0) {
        this.report = report;

        const firstEntry = report.runs[0].sections[0].entries[0];
        this.shouldProcess = firstEntry.widths?.length >= 3;

        if (!this.shouldProcess)
            return;

        // calculate the appropriate MGA zone
        const pos = report.runs[0].sections[0].entries.first(e => !!e.gps)?.gps;
        if (!pos) {
            // something is wrong. None of the entries have GPS data... not sure what we should do...
        }
        const zone = this.calculateMGAZone(pos.lat, pos.lon);
        const mga2020 = this.getEPSGCode(zone, true);

        //+towgs84=-0.06155,0.01087,0.04019,-0.0394924,-0.0327221,-0.03289790,0.009994
        proj4.defs(mga2020, `+proj=utm +zone=${zone} +south +ellps=GRS80 +units=m +no_defs`); // MGAxx GDA2020 -> WGS84

        // WGS84 -> MGAxx GDA2020
        this.transform = proj4(proj4.WGS84, mga2020);

        this.leftExtBox = leftExtBox;
        this.rightExtBox = rightExtBox;
        this.centreOffset = centreOffset;
    }

    processReport(): void {
        if (!this.shouldProcess) {
            console.log('Report data does not allow for width processing. Skipping...');
            return;
        }
        
        console.log('processing report widths...');

        // calculate width positions      
        for (const run of this.report.runs) {
            const runEntries = run.sections.flatMap(s => s.entries).filter(e => !!e.gps);
            if (runEntries.length <= 0) {
                continue;
            }

            // loop over entries & calculate positions
            this.tranformToMGA(runEntries[0]);
            for (let i = 1; i < runEntries.length; ++i) {
                const entry = runEntries[i];
                const prevEntry = runEntries[i - 1];

                this.tranformToMGA(entry); // calculate easting/northing values
                const isLast = i == runEntries.length - 1;

                prevEntry.widthPositions = this.calculateWidthPositions(entry, prevEntry, false);
                if (isLast) {
                    entry.widthPositions = this.calculateWidthPositions(entry, prevEntry, true);
                }
            }
        }
    }

    private tranformToMGA(entry: DataEntry): void {
        if (entry.gps) {
            try {
                const mga = this.transform.forward({ x: entry.gps.lon, y: entry.gps.lat });
                entry.gps.x = mga.x;
                entry.gps.y = mga.y;
            } catch (e) {
                console.error(e);
            }
        }
    }

    private calculateWidthPositions(entry: DataEntry, prevEntry: DataEntry, isLast: boolean): WidthsInfo {
        const widths = (isLast ? entry?.widths : prevEntry?.widths) ?? [0, 0, 0];
        const leftWidth = isNaN(widths[0]) ? 0.0 : widths[0];
        const rightWidth = isNaN(widths[1]) ? 0.0 : widths[1];
        const mainWidth = isNaN(widths[2]) ? 0.0 : widths[2];

        const rightOffset = rightWidth + (mainWidth / 2) + this.rightExtBox;
        const fullWidth = leftWidth + rightWidth + mainWidth + this.leftExtBox + this.rightExtBox;

        const vector = Vector.fromPoints(prevEntry.gps, entry.gps);
        const perpendicular = vector.perpendicularCounterClockwise().normalize();

        const pt = isLast ? entry.gps : prevEntry.gps;
        const centrePt: Point = {
            x: pt.x + (perpendicular.x * this.centreOffset),
            y: pt.y + (perpendicular.y * this.centreOffset)
        }

        const centreCoords = this.transform.inverse({ x: centrePt.x, y: centrePt.y });

        const rightExtPt = {
            x: centrePt.x + (perpendicular.x * rightOffset),
            y: centrePt.y + (perpendicular.y * rightOffset)
        }
        const rightExtCoords = this.transform.inverse({ x: rightExtPt.x, y: rightExtPt.y });

        const leftExtPt = {
            x: rightExtPt.x - (perpendicular.x * fullWidth),
            y: rightExtPt.y - (perpendicular.y * fullWidth)
        }
        const leftExtCoords = this.transform.inverse({ x: leftExtPt.x, y: leftExtPt.y });

        return {
            leftExtension: {
                lat: leftExtCoords.y,
                lon: leftExtCoords.x
            },
            rightExtension: {
                lat: rightExtCoords.y,
                lon: rightExtCoords.x
            },
            centre: {
                lat: centreCoords.y,
                lon: centreCoords.x
            }
        }
    }

    private calculateMGAZone(lat: number, lon: number): number {
        const zone = Math.floor((lon + 180) / 6) + 1;
        //const isSouthern = lat < 0;
        return zone;
    }

    private getEPSGCode(zone: number, is2020 = true): string {
        if (is2020) {
            return `EPSG:78${zone}`;
        } else {
            return `EPSG:283${zone}`;
        }
    }
}

export default PaverPositionProcessor;