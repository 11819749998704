import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import initializeStore from './state/store';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { initialize, update } from './state/types';
import './helpers/LINQ';
import { customTheme } from './customTheme';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import assignPolyfills from './helpers/polyfills';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVlJXmJWf1FpTGpQdk5yd19DaVZUTX1dQl9hSXlTckVmXHtfcHNVRGM=');

const rootElement = document.getElementById('root');

const store = initializeStore();

// declare module '@material-ui/core/styles/createMuiTheme' {
// 	interface ThemeOptions {
// 		[key: string]: unknown;
// 	}
// }

const root = createRoot(rootElement);

root.render(
	<MuiThemeProvider theme={customTheme}>
		<Provider store={store}>
			<App />
		</Provider>
	</MuiThemeProvider>
);

serviceWorkerRegistration.register({
	onSuccess: () => {
		store.dispatch(initialize());
	},
	onUpdate: (registration: ServiceWorkerRegistration) => {
		store.dispatch(update(registration));
	}
});

//reportWebVitals();
assignPolyfills();
