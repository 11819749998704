import { FilterToolbarState, FilterActions } from './types';
import { DateOnly, deserializeJobs, getSessionState } from '../../helpers/utils';
import { JobInfo } from '../../helpers/types';
import { setErrorMessage } from '../types';
import { createReducer, createAction, createAsyncThunk } from '@reduxjs/toolkit';

const innerState = getSessionState<FilterToolbarState>('filterState', {
	job: null,
	jobs: null,
	startDate: null,
	endDate: null,
	
	latestAction: null
});

const getRecentJobs = (jobs: JobInfo[]) => {
	const now = DateOnly.today();
	const recentJobs = jobs.filter(job => {
		const days = DateOnly.daysBetween(job.latestReportDate, now);
		return days <= 30.0;
	});

	return recentJobs;
}
			
const initialState: FilterToolbarState = {
	...innerState,
	refreshing: true,
	searchEnabled: innerState.job !== null
};

export const selectJob = createAction<JobInfo | string, FilterActions.SelectJob>(FilterActions.SelectJob);
export const selectStartDate = createAction<DateOnly, FilterActions.SelectStart>(FilterActions.SelectStart);
export const selectEndDate = createAction<DateOnly, FilterActions.SelectEnd>(FilterActions.SelectEnd);
export const startRefresh = createAction<void, FilterActions.StartRefresh>(FilterActions.StartRefresh);
export const endRefresh = createAction<JobInfo[], FilterActions.EndRefresh>(FilterActions.EndRefresh);
export const resetFilter = createAction<void, FilterActions.Reset>(FilterActions.Reset);

export type FilterActionTypes = ReturnType<typeof selectStartDate> | ReturnType<typeof selectEndDate> | 
	ReturnType<typeof startRefresh> | ReturnType<typeof endRefresh>	| 
	ReturnType<typeof selectJob> | ReturnType<typeof resetFilter>;

/********** thunk functions ********************/
const fetchJobs = createAsyncThunk(
	'filter/fetchJobs',
	async (getAccessToken: (() => Promise<string>), thunkApi) => {
		const { dispatch } = thunkApi;
		dispatch(startRefresh());

		let accessToken = null;
		try {
			accessToken = await getAccessToken();
		} catch (error) {
			console.error(error);
		}

		try {
			const resp = await fetch('reports/jobs-list', {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});

			// if error status (not OK status)
			if (resp.status !== 200) {
				dispatch(endRefresh([]));

				if (resp.status === 401) {
					dispatch(setErrorMessage('User Unauthorized\nTry Logging Out then Back In'));
				} else if (resp.status === 403) {
					dispatch(setErrorMessage('Permission was Denied.\nTry Refreshing or Logging Out/In'));
				} else if (resp.status === 500) {
					dispatch(setErrorMessage('Serious Server Error!\nThe Database Server May Be Down'));
				} else {
					dispatch(setErrorMessage('Unknown Error Occurred. \nTry Again?'));
				}

				return;
			}

			const txt = await resp.text();
			if (!txt) {
				dispatch(endRefresh([]));
				dispatch(setErrorMessage('Failed to Retrieve Request Data'));
				return;
			}

			const data = deserializeJobs(txt);
			if (!data) {
				dispatch(endRefresh([]));
				dispatch(setErrorMessage('Failed to Deserialize JSON Data'));
				return;
			}

			dispatch(endRefresh(data));
		} catch (e) {
			if (typeof e === 'string') {
				console.log(e);
				dispatch(setErrorMessage('Failed Retrieving Jobs List: ' + e));
			} else if (e instanceof Error) {
				console.log(e);
				dispatch(setErrorMessage('Failed Retrieving Jobs List: ' + e?.message));
			}

			dispatch(endRefresh(null));
		}
	}
);

const filterReducer = createReducer(initialState, builder => 
	builder
		.addCase(selectJob, (state, action) => {
			const payload = action.payload;

			if (typeof (payload) === 'string') {
				const job = state.jobs.first(j => j.jobId.toLowerCase() === payload.toLowerCase());
				state.job = job;
				state.searchEnabled = job !== null && job !== undefined;
			} else {
				state.job = payload;
				state.searchEnabled = payload !== null && payload !== undefined;
			}
		})
		.addCase(selectStartDate, (state, action) => {
			state.startDate = action.payload;
		})
		.addCase(selectEndDate, (state, action) => {
			state.endDate = action.payload;
		})
		.addCase(startRefresh, (state, _) => {
			// set refreshing but purposefully DON'T set jobs to null. 
			// by keeping jobs with previous list, we can fallback to the previous results
			state.refreshing = true;
		})
		.addCase(endRefresh, (state, action) => {
			let job = state.job;
			if (job && typeof (job) === 'string') {
				job = action.payload.first(j => j.jobId.toLowerCase() === (<string>job).toLowerCase());
			}

			state.refreshing = false;
			state.job = <JobInfo>job;
			if (action.payload) {
				state.jobs = action.payload;
			}
			state.recentJobs = (action.payload && getRecentJobs(action.payload)) ?? state.recentJobs;
		})
		.addCase(resetFilter, (state, _) => {
			state.jobs
				= state.recentJobs 
				= state.job
				= state.startDate
				= state.endDate
				= null;
			state.searchEnabled = false;
		}).addCase(fetchJobs.fulfilled, (_state, _action) => {
			
		})
);

export { fetchJobs }
export default filterReducer;