import {
	createContext, FC, ReactNode, useContext, useRef, useState, useCallback
} from 'react';

const reportViewerVersion = '1.1.0';

export interface ReportViewerContextProps {
	version: string
}

export const ReportViewerContext = createContext<Partial<ReportViewerContextProps>>({ });

export const useReportViewerContext = (): Partial<ReportViewerContextProps> => useContext(ReportViewerContext);

interface ProviderProps {
	children: ReactNode
}

/**
 * ReportViewer Context Provider component which wraps the input children props in a @see ReportViewerContext
 * @param param0 props parameter. only the 'children' property is extracted
 */
export const ReportViewerContextProvider: FC<ProviderProps> = ({ children }) => {
	const [version] = useState(reportViewerVersion);

    return (
        <ReportViewerContext.Provider value={{ version }}>
			{children}
        </ReportViewerContext.Provider>
    );
}