import { Location } from 'history'
import { DataEntry, ReportInfo, JobInfo, RunStats, ShiftInfo, RunInfo, QueryOptions } from "./types";
import './LINQ';
import TimeSpan from './TimeSpan';
import { format } from 'date-fns';
import { getRun } from './reportHelpers';

const isDebug = process.env.NODE_ENV !== 'production';

export type Selector<S, U> = (s: S) => U;

export type BaseTypeOfArray<T> = T extends Array<infer I> ? I : never;
export type ConstantOrFunction<F extends (...args: any[]) => any> = F | ReturnType<F>;
export type MaybeConstValue<T> = T extends (...args: any[]) => infer Return ? Return : T;

export type ReportQuery = {
    jobId: string,
    id: string,

    processWidths?: boolean
}

export function extractViewerQuery<T>(location: Location<T>): ReportQuery {
    const params = new URLSearchParams(location.search);

    if (params.has('jobId') && params.has('id')) {
        return {
            id: params.get('id'),
            jobId: params.get('jobId'),
            processWidths: params.get('processWidths') ?? false
        } as ReportQuery;
    }

    return null;
}

export function extractHistoryQuery<T>(location: Location<T>): QueryOptions {
    const params = new URLSearchParams(location.search);

    if (params.has('jobId')) {
        return {
            jobId: params.get('jobId'),
            startDate: params.has('startDate') ? DateOnly.parse(params.get('startDate')) : null,
            endDate: params.has('endDate') ? DateOnly.parse(params.get('endDate')) : null
        } as QueryOptions;
    }

    return null;
}

//function isIsoDate(str) {
//    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
//    var d = new Date(str);
//    return d.toISOString() === str;
//}

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.[0-9]+)?Z$|\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.[0-9]+)?[+-]\d{2}:\d{2}/;
//const isoDateFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.[0-9]+)?[+-]\d{2}:\d{2}/;

function dateReviver(key: string | string[], value: string | number) {
    // check if key is 'date' (from ShiftInfo which now uses our custom Date object)
    if ((key === 'date' || key === 'lrd' || key === 'latestReportDate' || key.indexOf('Date') >= 0)
        && typeof (value) === 'string') {
        if (value[0] === '{') {
            const dateFields = JSON.parse(value) as IDateOnlyFields;
            return new DateOnly(dateFields);
        }

        const split = value.split('/');
        if (split.length == 3 && split[2].length == 2 && split[1].length == 2) {
            // month is 0-indexed for some reason in js Date object
            return new DateOnly(parseInt(split[0]), parseInt(split[1]), parseInt(split[2]));
        }
    }

    // shortcut check if property is 'ts' (from DataEntry) or any property with 'date' in the name
    if (key === 'ts' || key === 'created' || key.indexOf('date') >= 0) {
        return new Date(value);
    } else if (typeof value === "string" && dateFormat.test(value)) { // only if the quick checks fail do we check using regex
        return new Date(value);
        //return parseISO(value);
    }

    return value;
}

const timeSpanFormat = /^\d{2}:\d{2}:\d{2}/;
const timeSpanFormat2 = /^[0-9]{2}:(.[0-9]+):(.[0-9]+)/;

function timeSpanReviver(key: string, value: unknown): TimeSpan | unknown {
    if (typeof value === 'string' && timeSpanFormat.test(value)) {
        return TimeSpan.parse(value as string);
    }

    return value;
}

function dateReplacer(key: string, value: unknown): unknown {
    if (value instanceof Date) {
        debug(`stringifying date: ${key} - ${value}`);

        if (value) {
            return (value as Date).toISOString();
        }
    }

    if (value instanceof DateOnly && value) {
        return value.toString();
    }

    return value;
}

export function customStringifyJSON<T>(obj: T): string {
    return JSON.stringify(obj, dateReplacer)
}

export function customParseJSON<T>(json: string): T {
    return JSON.parse(json, dateReviver) as T;
}

export function debug(msg: string): void {
    if (isDebug) {
        console.log(msg);
    }
}

export function print<T>(obj: T): void {
    debug(JSON.stringify(obj));
}

export function toTimeString(minutes: number): string {
    if (!minutes || minutes <= 0)
        return '—';

    const seconds = minutes % 1;

    if (minutes < 60.0) {
        return `${minutes.toFixed(0)}:${(seconds * 60).toFixed(0)}s`;
    } else {
        const mins = (minutes - seconds) % 60;
        const hours = minutes / 60.0;
        return `${hours.toFixed(0)}:${mins.toFixed(0)}:${(seconds * 60).toFixed(0)}s`;
    }
}

declare global {
    interface Window {
        cpuMonitorTimer: NodeJS.Timeout;
        cpuMonitorStartTime: Date;
    }
}

export function startCpuMonitor(): void {
    if (!window.cpuMonitorTimer) {
        window.cpuMonitorStartTime = new Date(Date.now());

        window.cpuMonitorTimer = setInterval(() => {
            const totalTime = (Date.now() - window.cpuMonitorStartTime.getTime()) * 1000; // time since monitor started (in microseconds)

            const usage = process.cpuUsage();
            const percent = (usage.system + usage.user) / totalTime;

            console.log(`CPU: ${percent}%`);
        }, 5000);
    }
}

export function stopCpuMonitor(): void {
    clearInterval(window.cpuMonitorTimer);
    window.cpuMonitorTimer = null;
}

interface JobInfoJSON {
    id: string;
    lrd: DateOnly;
    rc: number;
}

export function deserializeJobs(json: string): JobInfo[] {
    const jobsJSON = customParseJSON<JobInfoJSON[]>(json);

    if (jobsJSON) {
        return jobsJSON.map(job => {
            return {
                jobId: job.id,
                latestReportDate: job.lrd,
                reportCount: job.rc
            } as JobInfo;
        });
    }

    return [];
}

export function getDataEntries(report: ReportInfo, run: number): DataEntry[] {
    return getRunDataEntries(getRun(report, run));
}

export function getRunDataEntries(runInfo: RunInfo): DataEntry[] {
    if (!runInfo)
        return null;

    return runInfo
        .sections
        .selectMany(s => s.entries)
        .where((item: DataEntry, previous: DataEntry) => {
            if (!previous || item.t === 'Recording')
                return true;

            return item.ts > previous.ts; // filter out entries with exactly same timestamp
        })
        .toArray();
        //.sort((x, y) => x.ch - y.ch);
}

export function isHighQualityGNSS(report: ReportInfo): boolean {
    const entries = getDataEntries(report, report.runs[0].runNumber);
    return entries.some(e => e.gps?.fix === 'Fixed');
}

export function getLayers(report: ReportInfo): string {
    let layers = '';
    const runLayers = report.runs.map(r => r.layer);

    // grab the layers with the format 'Layer X' first
    for (const layer of runLayers.filter(layer => layer.startsWith('Layer'))) {
        if (layers === '') {
            layers += layer;
        } else {
            layers += '/' + layer.substring(6);
        }
    }

    // now grab any layers which don't follow the 'Layer X' format (such as 'Wearing Course')
    for (const layer of runLayers.filter(layer => !layer.startsWith('Layer'))) {
        if (layers === '') {
            layers += layer;
        } else {
            layers += '/' + layer;
        }
    }

    return layers;
}

export function hasSensorReadings(data: DataEntry[], sensor: number): boolean {
    return !!data.find(e => e.temps[sensor - 1] > 25);
}

export function hasLongerRange(data: DataEntry[], prevRange: number): boolean {
    if (!data || data.length <= 0)
        return false;

    const range = Math.abs(data.last().ch - data.first().ch);
    return range > prevRange;
}

export function getAvailableRanges(data: DataEntry[]): number[] {
    if (!data || data.length <= 0) {
        return;
    }

    const range = Math.abs(data.last().ch - data.first().ch);

    const ranges = new Array<number>();
    ranges.push(25);

    if (range > 25)
        ranges.push(50);
    if (range > 50)
        ranges.push(100);
    if (range > 100)
        ranges.push(250);

    return ranges;
}

export function getMaxRange(data: DataEntry[]): number {
    if (!data)
        return -1;

    const range = Math.abs(data.last().ch - data.first().ch);

    if (range > 250)
        return 10000; // this is the 'All' option
    if (range > 100)
        return 250;
    if (range > 50)
        return 100;
    if (range > 25)
        return 50;

    return 25;
}

export function getSessionState<S>(key: string, defaultValue: S | (() => S)): S {
    const json = sessionStorage.getItem(key);

    if (json) {
        if (json === 'undefined') {
            return getValue(defaultValue);
        }

        const parsed = customParseJSON<S>(json);
        if (parsed) {
            return parsed;
        }
    }

    return getValue(defaultValue);
}

export function getValue<S>(src: S | (() => S)): S {
    if (typeof src === 'function') {
        return (src as (() => S))() as S;
    }

    return src as S;
}

export function isTouchDevice(): boolean {
    return !!('ontouchstart' in window        // works on most browsers 
        || navigator.maxTouchPoints);       // works on IE10/11 and Surface
}

export function isScrollbarVisible(/*element*/): boolean {
    //return element.scrollHeight > element.clientHeight;
    return window.visualViewport.width < window.innerWidth;
}

export function logInfo(header: string, msg: string): Promise<boolean> {
    const query = new URLSearchParams();
    query.append('header', header);
    query.append('msg', msg);

    return fetch(`/logger/info?${query.toString()}`).then(response => response.status === 200);
}

export function makeTimeString(date: Date, is24hr = true): string {
    if (!date)
        return '';

    let hours = date.getHours();
    const minutes = date.getMinutes();

    if (is24hr) {
        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    } else {
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        const strTime = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;

        return strTime;
    }
}

export function makeDateTimeString(date: Date, make24hr = true): string {
    if (!date)
        return '';

    if (make24hr) {
        return format(date, 'dd/MM/yy HH:mm aa');
    } else {
        return format(date, 'dd/MM/yy hh:mm aa');
    }
}

/**
 * Promise-based delay function
 * @param timeout is number of milliseconds to delay for
 */
export function delay(timeout: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, timeout));
}

export function roundToNearest(value: number, target: number): number {
    return Math.round(value / target) * target;
}

export function ceilToNearest(value: number, target: number): number {
    return Math.ceil(value / target) * target;
}

declare global {
    interface DateConstructor {
        dateNow(): Date;
        toDateString(): string;
    }

    interface Number {
        absolute(): number;
    }
}

if (!Number.prototype.absolute) {
    Number.prototype.absolute = function (this: number): number {
        return Math.abs(this);
    }
}

if (!Date.dateNow) {
    Date.dateNow = function (): Date {
        return new Date(Date.now());
    }
}

if (!Date.toDateString) {
    Date.toDateString = function (this: Date): string {
        return `${this.getFullYear()}/${this.getMonth().toString().padStart(2, '0')}/${this.getDay().toString().padStart(2, '0')}`;
    }
}

const createRingBuffer = function<T>(length: number) {
    let pointer = 0;
    let count = 0;
    const buffer: T[] = [];

    return {
        get: function (key: number) { return buffer[key]; },
        getAll: function () { 
            if (count < length) {
                return buffer.slice(0, count);
            }

            return buffer;
        },
        push: function (item: T) {
            buffer[pointer] = item;
            pointer = (length + pointer + 1) % length;

            if (count < length) {
                ++count;
            }
        }
    };
};

/*
 * This function is used to smooth GPS data. 
 * It takes a window size and returns a generator that yields the smoothed data.
 * 
 * TODO: replace with projection-based algorithm to properly preserve chainage
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* smoothGPS(run: DataEntry[], windowSize = 1): Generator<DataEntry, any, unknown> {
    if (windowSize % 2 == 0) {
        windowSize += 1;
    }

    const halfWindow = (windowSize - 1) / 2;
    const ringBuffer = createRingBuffer<DataEntry>(windowSize);

    for (const e of run.slice(0, halfWindow)) {
        ringBuffer.push(e);
        yield e;
    }

    for (let i = halfWindow; i < run.length; ++i) {
        const e = run[i];
        ringBuffer.push(e);

        yield {
            ...e,
            gps: {
                lat: ringBuffer.getAll().reduce((sum, e) => sum + e.gps.lat, 0) / windowSize,
                lon: ringBuffer.getAll().reduce((sum, e) => sum + e.gps.lon, 0) / windowSize
            }
        }
    }
}

export interface IDateOnlyFields {
    year: number;
	month: number;
	day: number;
}

export interface IDateOnly extends IDateOnlyFields {
    valueOf?(): number;
    toString?(): string;
    toJSON?(): string;

    toDate?(): Date;

    /**
     * Converts an IDateOnly object to a number or string depending on the hint
     * @param hint tells us whether to convert to a string or number primitive
     */
    [Symbol.toPrimitive]?(hint: string): number | string;
}

type DateOnlyArgs = IDateOnlyFields | number;

export class DateOnly implements IDateOnly {
	year: number;
	month: number;
	day: number;

    constructor(date: IDateOnlyFields);
    constructor(year?: number, month?: number, day?: number);
    constructor(...args: DateOnlyArgs[]) {      
        if (args.length === 1) {
            // IDateOnlyFields constructor
            const date = args[0] as IDateOnlyFields;
            if (date) {
                this.year = date.year;
                this.month = date.month;
                this.day = date.day;
            }
        } else if (args.length === 3) {
            // year, month, day constructor
            this.year = <number>args[0];
            this.month = <number>args[1];
            this.day = <number>args[2];
        }
    }

    [Symbol.toPrimitive] = function (hint: string): number | string {
		if (hint === 'string')
			return this.toString();

		return this.valueOf();
    }

    valueOf(): number {
        const d = new Date(this.year, this.month - 1, this.day, 12, 0, 0, 0);
        return d.valueOf();
    }

    toString(): string { 
        return `${this.year}/${this.month.toString().padStart(2, '0')}/${this.day.toString().padStart(2, '0')}`;
    }

    toStringDayFirst(): string { 
        return `${this.day.toString().padStart(2, '0')}/${this.month.toString().padStart(2, '0')}/${this.year}`;
    }

    toStringJSON(): string {
        return JSON.stringify({
            day: this.day,
            month: this.month,
            year: this.year
        });
    }

    // toJSON(): string {
    //     return JSON.stringify({
    //         day: this.day,
    //         month: this.month,
    //         year: this.year
    //     });
    // }

    toDate(): Date {
        return new Date(this.year, this.month - 1, this.day, 12, 0, 0, 0);
    }

    public static parse(text: string): DateOnly {
        if (!text) {
            return null;
        }

        const split = text.split('/');
        if (split.length !== 3) {
            return null;
        }

        const year = parseInt(split[0]);
        const month = parseInt(split[1]);
        const day = parseInt(split[2]);

        return new DateOnly(year, month, day);
    }

    public static fromInterface(date: IDateOnly): DateOnly {
        return new DateOnly(date.year, date.month, date.day);
    }

    public static fromDate(date: Date): DateOnly {
        if (!date) {
            return null;
        }

        return new DateOnly(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    public static today(): DateOnly {
        return DateOnly.fromDate(new Date(Date.now()));
    }

    public static daysBetween(start: IDateOnly, end: IDateOnly): number {
        if (!start || !end) {
            return Number.MAX_VALUE;
        }

        if (start instanceof DateOnly && end instanceof DateOnly)
            return (end.toDate().getTime() - start.toDate().getTime()) / (1000 * 60 * 60 * 24);
        
        const ds = DateOnly.fromInterface(start);
        const de = DateOnly.fromInterface(end);

        return (de.toDate().getTime() - ds.toDate().getTime()) / (1000 * 60 * 60 * 24);
    }
}

export function testDateOnly() {
	const x = new DateOnly(2022, 1, 1);
	const y = new DateOnly(2022, 1, 2);

	if (y > x) {
		console.log('y > x');
	} else {
		console.log('error comparing DateOnly objects');
	}
}