import { lazy, Suspense, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, useLocation } from "react-router";
import { Switch } from "react-router-dom";
import { Home } from "./components/Home";
import Layout from "./components/Layout";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { ReportViewerContextProvider } from "./helpers/ReportViewerContext";
import { Router } from "react-router-dom";
import "./custom.css";
import Loader from "./components/controls/Loader";
import Colors from "./components/core/Colors";
import useLoadedState from "./helpers/hooks/useLoadedState";
import { useAuth0 } from "@auth0/auth0-react";
import { getValue } from "./helpers/utils";
import UserInfo from "./components/pages/UserInfo";

const getHistory = callback => {
    const promise = import(/* webpackPrefetch: true */ "./components/pages/History").then(module => {
        callback();
        return module;
    });

    return lazy(() => promise);
};

const getViewer = callback => {
    const promise = import(/* webpackPrefetch: true */ "./components/pages/Viewer").then(module => {
        callback();
        return module;
    });

    return lazy(() => promise);
};

const getUploader = callback => {
    const promise = import(/* webpackPrefetch: true */ "./components/pages/Uploader").then(module => {
        callback();
        return module;
    });

    return lazy(() => promise);
};

export const browserHistory = createBrowserHistory();

const ProtectedRoute = ({ component, path }) => {
    return <Route path={path} component={withAuthenticationRequired(component)} />;
};

ProtectedRoute.propTypes = {
    component: PropTypes.object,
    path: PropTypes.string.isRequired
};

const onRedirectCallback = appState => {
    // Use the router's history module to replace the url
    //history.replace(appState?.returnTo || window.location.pathname);
    browserHistory.push(appState?.returnTo || window.location.pathname);
};

function App() {
    const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
    console.log("base url: " + baseUrl);

    return (
        /* 
         * instead of requesting scopes such as read:reports list:reports read:status, the RBAC auth0 system adds the permission claims
         * with the role-based permissions that the user has 
         **/
        <Router basename={baseUrl} history={browserHistory}>
            <Auth0Provider
                domain='paveset-reporter.au.auth0.com'
                clientId='8BfnkEKjdKVNaAzcxKvdxcnwxzBJtlBd'
                redirectUri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
                cacheLocation='localstorage'
                audience='https://paveset-reporter-backend.com'
                scope='openid profile email offline_access'
                useRefreshTokens={true}>
                <ReportViewerContextProvider>
                    <LayoutWrapper />
                </ReportViewerContextProvider>
            </Auth0Provider>
        </Router>
    );
}

/**
 * @ignore
 */
const defaultOnRedirecting = () => (
    <>
        <Loader centered text='redirecting to login page...' type='chase' size={40} />
        {/*<p style={{ position: "absolute", marginLeft: "calc(50% - 20px)", marginTop: 40 }}>Redirecting to Login Page</p>*/}
    </>
);

/**
 * @ignore
 */
const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`;

function LayoutWrapper(props) {
    //const { historyStatus, viewerStatus, uploaderStatus } = useReportViewerContext();
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const location = useLocation();
    const [options, setOptions] = useState({
        returnTo: defaultReturnTo,
        onRedirecting: defaultOnRedirecting,
        loginOptions: {}
    });

    useEffect(() => {
        console.log("<<< AuthState Changed >>>");
        //console.log({ isLoading, isAuthenticated, loginWithRedirect, options, location });

        // check if path is Home page, so auth is not required
        if (location.pathname === "/") {
            console.log("<<< Authentication Not Required >>>");
            return;
        }

        if (isLoading || isAuthenticated) {
            console.log("<<< Already Authenticated >>>");
            return;
        }

        console.log("<<< Not Authenticated Yet >>>");
        const opts = {
            ...options.loginOptions,
            appState: {
                ...options.loginOptions.appState,
                returnTo: getValue(options.returnTo)
            }
        };

        (async () => {
            console.log("<<< Logging-In With Redirect >>>");
            await loginWithRedirect(opts);
        })();
    }, [isLoading, isAuthenticated, loginWithRedirect, options, location]);

    const historyStatus = useLoadedState();
    const viewerStatus = useLoadedState();
    const uploaderStatus = useLoadedState();

    const HistoryPage = useMemo(() => getHistory(() => {
        historyStatus.setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const ViewerPage = useMemo(() => getViewer(() => {
        viewerStatus.setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const UploaderPage = useMemo(() => getUploader(() => {
        uploaderStatus.setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

    const renderPage = (Component, props) => {
        return (
            <>
                {isAuthenticated && <Component {...props} />}
                {!isAuthenticated && options.onRedirecting()}
            </>
        );
    };

    const renderPageWithoutAuth = (Component, props) => {
        return (<Component {...props} />);
    };

    return (
        <Layout {...props} historyLoaded={historyStatus.loaded} viewerLoaded={viewerStatus.loaded} uploaderLoaded={uploaderStatus.loaded}>
            <Suspense fallback={<Loader centered type='circle' color={Colors.accent} size={50} text='Loading Page...' delayed />}>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/history' render={props => renderPage(HistoryPage, props)} />
                    <Route path='/viewer' render={props => renderPage(ViewerPage, props)} />
                    <Route path='/uploader' render={props => renderPage(UploaderPage, props)} />
                    <Route path='/user' render={props => renderPage(UserInfo, props)} />
                    {/* <Route path='/user' render={(props) => renderPage(UserInfo, props)} /> */}
                    {/* default route if no other route is matched */}
                    <Route component={Home} />
                </Switch>
            </Suspense>
        </Layout>
    );
}

export default App;
