import React from 'react';

interface ErrorState {
    hasError: boolean;
}

interface ErrorProps {
    children?: React.ReactNode
}

class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    state: ErrorState;

    constructor(props: ErrorProps) {
        super(props);

        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: unknown): ErrorState {
        return {
            hasError: true
        };
    }

    componentDidCatch(error: unknown, errorInfo: unknown): void {
        console.error(error);
        console.error(errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return <h4>Error During Render...</h4>;
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;