import './LINQ';
import { Coord, DataEntry, EntryType, FixStatus, Layer, ReportInfo, RunInfo, Shift, ShiftInfo, StopInfo, ValueRange } from './types';
import { customParseJSON, getDataEntries } from './utils';

export function initializeRunDirections(report: ReportInfo): void {
    for (const run of report.runs || []) {
        const first = run.sections?.first()?.entries?.first();
        const last = run.sections?.last()?.entries?.last();

        if (first && last) {
            run.direction = first.ch < last.ch ? 'Increasing' : 'Decreasing';
        } else {
            run.direction = 'Increasing';
        }
    }
}

export function getPavingTimes(report: ReportInfo): ValueRange<Date> {
    if (!report) {
        return null;
    }

    const range = report.runs.selectMany(r => r.sections.selectMany(s => s.entries)).rangeBy(e => e.ts.getTime());

    return {
        min: range?.min?.ts, 
        max: range?.max?.ts 
    };
}

export function getStartTime(report: ReportInfo): Date {
    if (!report || report.runs.length <= 0) {
        return null;
    }

    const start = report.runs?.selectMany(r => r?.sections?.selectMany(s => s?.entries)).minBy(e => e.ts.getTime());
    return start?.ts;
}

export function getRun(report: ReportInfo, run: number): RunInfo {
    if (run <= 0) {
        return null;
    }

    return report?.runs?.find(r => r.runNumber === run);
}

export function getPavingDirection(report: ReportInfo, run: number): 'Increasing' | 'Decreasing' {
    const runEntries = getDataEntries(report, run);
    if (!runEntries) {
        return 'Increasing';
    }

    const startEntry = runEntries.minBy(e => e.ts.getTime());
	const endEntry = runEntries.maxBy(e => e.ts.getTime());
    return endEntry.ch > startEntry.ch ? 'Increasing' : 'Decreasing';
}

export function getAllEntries(report: ReportInfo): Generator<DataEntry> {
    return report?.runs?.selectMany(r => r?.sections?.selectMany(s => s?.entries));
}

/**
 * Performs the filtering/cleanup which used to be done when the report was uploaded but instead is now performed on-the-fly
 * This allows us to switch between chainage-based and time-based plots
 * @param entries The raw collection of entries prior to filtering
 */
export function filterSection(entries: DataEntry[]): DataEntry[] {
    const averageTemps = (grp: DataEntry[]): number[] => {
        const averages = new Array<number>(grp[0].temps.length);

        for (const entry of grp) {
            for (let i = 0; i < averages.length; ++i) {
                averages[i] += entry.temps[i];
            }
        }

        for (let i = 0; i < averages.length; ++i) {
            averages[i] /= grp.length;
        }

        return averages;
    }

    const averageCoords = (grp: DataEntry[]): Coord => {
        let lat = 0.0, lon = 0.0;

        let count = 0;
        for (const entry of grp.where(x => !!x.gps)) {
            lat += entry.gps.lat;
            lon += entry.gps.lon;
            ++count;
        }

        return {
            lat: lat / count,
            lon: lon / count
        }
    }

    const map = entries.groupBy(e => e.wheel);
    const filtered: DataEntry[] = [];
    
    map.forEach((values) => {
        if (values.length === 1) {
            filtered.push(values[0]);
        } else {
            const newEntry: DataEntry = {
                ...values[0],
                t: 'Stop',
                temps: averageTemps(values),
                gps: averageCoords(values)
            }

            filtered.push(newEntry);
        }
    });

    return map.values().toArray().sort((a, b) => a.ts.getTime() - b.ts.getTime());
}


export function getChainageRange(run: RunInfo): ValueRange<number> {
    return {
        min: run?.sections?.[0]?.startChainage,
        max: run?.sections?.last()?.endChainage
    }
}

function compare(x: unknown, y: unknown): number {
    if (x < y) return -1;
    if (x > y) return 1;
    return 0;
}

export function compareLayers(x: string, y: string): number {
    // check if x and/or y are 'Patches'
    if (x === 'Patches') {
        if (y === 'Patches')
            return 0;
        return -1;
    }

    if (y === 'Patches')
        return 1;

    // if neither are Patches, then a regular comparison will sort Layer X/Wearing Course strings
    return compare(x, y);
}

export function hasGPS(runEntries: DataEntry[] | Iterable<DataEntry>): boolean {
    return runEntries.any(e => !!e.gps && e.gps.lat !== 0 && e.gps.lon !== 0);
}

export function hasAnyGPS(report: ReportInfo): boolean {
    return hasGPS(getAllEntries(report));
}

export function countStops(runEntries: DataEntry[]): number {
	return findStops(runEntries).length;
}

export function findStops(runEntries: DataEntry[]): StopInfo[] {
    const stops: StopInfo[] = [];

    let isStopped = false;

    for (let i = 0; i < runEntries.length - 1; ++i) {
		const isStopType = runEntries[i].t === 'Stop';

		if (isStopType && !isStopped) {
			isStopped = true;
            const entry = runEntries[i];
            if (entry.stopTime) {
                stops.push({
                    chainage: entry.ch,
                    stopTime: entry.stopTime
                });
            } else {
                const next = runEntries[i + 1];
                const time = (next.ts.getTime() - entry.ts.getTime()) / 1000;

                stops.push({
                    chainage: entry.ch,
                    stopTime: time
                });
            }
        } else if (!isStopType && isStopped) {
            if (runEntries[i].spd >= 1.0) {
				isStopped = false;
            }
        }
    }

    return stops;
}

/**
 * Calculates the time (in minutes) between the end of the 'previous' run and the start of the current run
 * @param report The report object
 * @param currentRun The runNumber for the current run
 * @returns The number of minutes from end of previous run to start of current run
 */
export function timeSincePreviousRun(report: ReportInfo, currentRun: number): number {
    if (!report || !currentRun || currentRun <= 0) {
        return null;
    }

    for (let i = 1; i < report.runs.length; ++i) {
        if (report.runs[i].runNumber === currentRun) {
            const currentRun = report.runs[i];
            const prevRun = report.runs[i - 1];

            const endPrev = getDataEntries(report, prevRun.runNumber).max(e => e.ts.getTime());
            const startCurrent = getDataEntries(report, currentRun.runNumber).min(e => e.ts.getTime());

            return (startCurrent - endPrev) / 1000 / 60;
        }
    }

    return null;
}

interface IReportMethods {
    hasGPS(): boolean;
}

export class Report implements ReportInfo, IReportMethods {
    id: string;
    company: string;
    overview: ShiftInfo;
    runs?: RunInfo[];

    constructor(report: ReportInfo) {
        this.id = report.id;
        this.company = report.company;
        this.overview = report.overview;
        this.runs = report.runs;
    }

    hasGPS(): boolean {
        return hasGPS(getAllEntries(this));
    }

    getRunEntries(runNumber: number): DataEntry[] {
        return getDataEntries(<ReportInfo>this, runNumber);
    }

    getLayers(): string {
        let layers = '';
        const runLayers = this.runs.map(r => r.layer);

        // grab the layers with the format 'Layer X' first then 
        for (const layer of runLayers.filter(layer => layer.startsWith('Layer')).concat(runLayers.filter(layer => !layer.startsWith('Layer')))) {
            if (layers === '') {
                layers += layer;
            } else {
                layers += '/' + layer.substring(6);
            }
        }

        // now grab any layers which don't follow the 'Layer X' format (such as 'Wearing Course')
        for (const layer of runLayers.filter(layer => !layer.startsWith('Layer'))) {
            if (layers === '') {
                layers += layer;
            } else {
                layers += '/' + layer;
            }
        }

        return layers;
    }
}


//-----------------------------------------------------------------------------//
//---------- Report Bundle Processing -----------------------------------------//
//-----------------------------------------------------------------------------//
export interface ReportBlobInfo {
    id: string;
    blobSAS: string;
    queueSAS: string;
}

export interface BundleRunInfo {
    runNumber: number;
    layer: Layer;

    sections: BundleSectionInfo[];
}

export interface BundleSectionInfo {
    runSection: number;
    startIndex: number;
    count: number;

    startChainage: number;
    endChainage: number;
}

export interface IReportBundle {
    id: string;
    company: string;
    overview: ShiftInfo;

    runs: BundleRunInfo[];
    entries: string[];
    diagnostics?: string[];
}

export type ReportStatus = 'Processing' | 'Completed' | 'Failed';
export type OperationStatus = 'Success' | 'NotFound' | 'InvalidID' | 'ServerError' | 'Error' | 'Cancelled' | 'Unknown';

export interface ReportProcessStatus {
    id: string;
    status: ReportStatus;
    reportUrl: string;
    errorMessage: string;

    started: Date;
    completed?: Date;
}

export interface ReportSectionDetails {
    runNumber: number;
    sectionNumber: number;
    isSingleSection?: boolean;

    layer: Layer;

    startChainage?: number;
    endChainage?: number;
    
    startTime?: Date;
    endTime?: Date;

    entries: DataEntry[]
}

interface RunDetails {
    runNumber: number,
    sections?: ReportSectionDetails[];
}

/**
 * Interface representing raw report data (most likely from a file).
 * This data is already filtered and cleaned by the mobile app
 * */
export interface IRawReportData {
    company: string;
    entries: string[];
    diagnostics: string[];
}

interface ParsedEntry {
    entry: DataEntry;
    index: number;
}

/*
 * Parses the raw report data into a DataEntry object
 * -> Updated to v2 format
 */
function parseDataEntry(line: string): DataEntry {
    if (!line) {
        return null;
    }

    const split = line.split(',');

    const shiftBinary = parseInt(split[0]);
    const run = parseInt(split[1]);
    const rc = parseInt(split[2]);
    const typeNumber = parseInt(split[3]);
    const mainScreed = parseInt(split[4]);
    const ts = new Date(Date.parse(split[5]));
    const wheel = parseInt(split[6]);
    const d = parseFloat(split[7]);

    const temps: number[] = [];
    for (let i = 0; i < 4; ++i) {
        if (split.length > i + 3) {
            const temp = parseInt(split[i + 8]);
            if (temp) {
                temps.push(temp);
            }
        }
    }

    const leftExt = parseInt(split[12]);
    const rightExt = parseInt(split[13]);

    let loc: Coord = null;

    if (split.length > 14) {
        const lat = parseFloat(split[14]);
        const lon = parseFloat(split[15]);

        loc = { lat, lon };

        if (split.length > 15) {
            const fix = parseInt(split[16]);
            loc.fix = convertFixStatus(fix);
        }

        if (split.length > 16) {
            loc.age = parseInt(split[17]);
        }
    }

    return {
        t: convertEntryType(typeNumber),
        ts,
        shift: parseShiftBinary(shiftBinary),
        rc,
        run,
        wheel,
        d,
        temps,
        gps: loc,
        widths: [leftExt, rightExt, mainScreed]
    };
}

function parseShiftBinary(shift: number): Shift {
    const year = shift >> 16;
    const month = (shift >> 12) & 0xF;
    const day = (shift >> 4) & 0xFF;
    const isNightShift = (shift & 1) == 1;

    return {
        year,
        month,
        day,
        isNightShift
    }
}

function convertEntryType(type: number): EntryType {
    switch (type) {
        case 0: return 'Unknown';
        case 1: return 'Mark';
        case 2: return 'Start';
        case 3: return 'Stop';
        case 4: return 'Timer';
        case 5: return 'StartRecording';
        case 6: return 'EndRecording';
        case 7: return 'Reset';
        case 8: return 'LoLa';
        default: return 'Unknown';
    }
}

function convertFixStatus(fix: number): FixStatus {
    switch (fix) {
        case 0: return 'NoFix';
        case 1: return 'Single';
        case 2: return 'Float';
        case 3: return 'Fixed';
        case 4: return 'PPP';
        case 5: return 'PPPAR';
        case 6: return 'FixedPPP';
        default: return 'NoFix';
    }
}

function parseEntry(line: string, index: number): ParsedEntry {
    const entry = parseDataEntry(line);
    return {
        entry,
        index
    };
}

interface RunGroup {
    run: number;
    entries: ParsedEntry[];
}

function groupByRun(entries: ParsedEntry[]): RunGroup[] {
    const groups: RunGroup[] = [];

    let prevItem = entries[0];
    let startGrpItem = entries[0];

    for (const item of entries) {
        if (item.entry.run !== prevItem.entry.run) {
            const grpEntries = entries.slice(startGrpItem.index, prevItem.index + 1);
            const run = startGrpItem.entry.run;

            groups.push({
                run: run,
                entries: grpEntries
            });

            startGrpItem = item;
        }

        prevItem = item;
    }

    return groups;
}

function findSections(run: number, entries: ParsedEntry[]): ReportSectionDetails[] {
    const sectionDetails: ReportSectionDetails[] = [];
    
    return sectionDetails;
}

export function extractRunDetails(lines: string[]): ReportSectionDetails[] {
    const entries = lines.map((line, index) => parseEntry(line, index));

    const groups = groupByRun(entries);

    const runDetails: ReportSectionDetails[] = [];

    for (const grp of groups) {
        const sections = findSections(grp.run, grp.entries);
        if (sections && sections.length > 0) {
            runDetails.push(...sections);
        }
    }

    return runDetails;
}

export class ReportService {
    private getAccessToken: () => Promise<string>;

    constructor(getAccessToken: () => Promise<string>) {
        this.getAccessToken = getAccessToken;
    }

    private getTemporaryBlob(): Promise<ReportBlobInfo> {
        return this.getAccessToken().then(token => {
            if (!token) {
                throw 'Failed Retrieving Access Token';
            }

            return fetch('/reports/sas?compressed=false', {
                method: 'get',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        }).then(resp => {
            if (resp.status === 200) {
                return resp.json();
            }
        }).then(json => {
            const info = json as ReportBlobInfo;
            if (info) {
                return info;
            }
        }).catch(error => {
            return null as ReportBlobInfo;
        });
    }

    async uploadReport(bundle: IReportBundle, progress?: (prog: number) => void): Promise<boolean> {
        try {
            const tempInfo = await this.getTemporaryBlob();
            if (!tempInfo)
                return false;


            return true;
        } catch (error) {
            return false;
        }
    }
}

