import { useState, useCallback } from 'react';

interface LazyPageState {
	loaded: boolean;
	setLoaded: (value: boolean) => void;
}

export default function useLoadedState(): LazyPageState {
	const [loaded, setLoaded] = useState<boolean>(false);
	const setLoadedState = useCallback((loadedState: boolean) => {
		setLoaded(loadedState);
	}, []);

	return {
		loaded: loaded,
		setLoaded: setLoadedState
	};
}