import { applyMiddleware, Store, StoreEnhancer } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { rootReducer } from './reducer';
import { RootState, AllActions } from './types';
import { persistStoreMiddleware } from './persistStoreMiddleware';
import { FilterActions, HistoryActions } from './history/types';

const developmentDevToolsOptions = {
	// put redux-devtools config here...
	features: {
		pause: true,
		test: true,
		persist: true,
		jump: true
	}
};

const productionDevToolsOptions = {
	features: {
		pause: true,
		export: true,
		test: true
	},

	/**
	 * Sanitizes the current state by replacing very large properties (such as the report data rows) with simple
	 * overviews of the underlying data. This way it doesn't explode the state viewer when debugging
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	stateSanitizer: (state: any, _index) => {
		const rootState = state as RootState;
		if (rootState.viewer.report || rootState.viewer.plotEntries) {
			try {
				return {
					...state,
					viewer: {
						...state.viewer,
						report: {
							...state.viewer.report,
							runs: `<< REPORT RUNS: ${rootState.viewer.report?.runs?.length} run(s) >>`
						},
						runEntries: rootState.viewer.runEntries != null && `<< RUN ENTRIES: ${rootState.viewer.runEntries?.length} entries >>`,
						plotEntries: rootState.viewer.plotEntries != null && `<< PLOT ENTRIES: ${rootState.viewer.plotEntries?.first()?.ch} to ${rootState.viewer.plotEntries?.last()?.ch} >>`
					}
				} as RootState;
			} catch {
				return state;
			}
		}

		return state;
	}
}

const getDevToolsOptions = () => {
	if (process.env.NODE_ENV === 'production') {
		return productionDevToolsOptions;
	} else {
		return developmentDevToolsOptions;
	}
};

function initializeStore(): Store<RootState, AllActions> {
	const store = configureStore({
		reducer: rootReducer,
		devTools: getDevToolsOptions(),
		middleware: (getDefaultMiddleware) => {
			const middlewares = getDefaultMiddleware({
				thunk: true,
				serializableCheck: false,
				immutableCheck: false,
				// serializableCheck: {
				// 	// ignore Date-related serializable errors 
				// 	ignoredActions: [FilterActions.END_REFRESH, FilterActions.SELECT_START, FilterActions.SELECT_END, HistoryActions.SEARCH_REPORTS],
				// 	ignoredActionPaths: ['payload.latestReportDate'],
				// 	ignoredPaths: ['filterToolbar.startDate', 'filterToolbar.endDate', 'filterToolbar.jobs', 'filterToolbar.job.latestReportDate']
				// }
			}).concat(persistStoreMiddleware);

			//console.log(middlewares);
			return middlewares;
		},
		//preloadedState,
	});

	
	return store;
	// if (process.env.NODE_ENV !== 'production' && module.hot) {
    // 	module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
	// }

    //return createStore(rootReducer, getEnhancers()(applyMiddleware(persistStoreMiddleware, thunk)));
}

export default initializeStore;