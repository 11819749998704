import { Typography, Button, Grid } from '@material-ui/core';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { useEffect } from 'react';

const HeaderText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('xs')]: {
        fontSize: '1.25rem'
    }
}));

const BodyText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',

    [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
    }
}));

// eslint-disable-next-line react/prop-types
export function Home() {
    const location = useLocation();
    const history = useHistory();

    if (location.pathname !== '/') {
        history.replace('/');
    }

    useEffect(() => {
        console.log('mounting Home');
        document.title = 'ReportViewer - Home';

        return () => {
            console.log('un-mounting Home');
        };
    });

    return (
        <div style={{ paddingLeft: 16 }}>

            <Grid container>
                <Grid item xs={3} sm={2} lg={1} style={{ display: 'flex' }}>
                    <img src='./PavesetLogo_x512.png' style={{ display: 'block', width: 60, height: 60, margin: 'auto' }} />
                </Grid>

                <Grid item xs={9} sm={10} lg={11}>
                    <HeaderText                   
                        variant="h5"
                        align="left"
                        style={{ marginTop: "1rem", marginBottom: "1rem", fontWeight: 700 }}>
                        Paveset Australia
                    </HeaderText>
                    <HeaderText
                        variant="h6"
                        align="left"
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        ReportViewer Web App
                    </HeaderText>
                </Grid>
            </Grid>

            <BodyText align="left" variant='body1'>
                Navigate to the
				<Link to="/history" >
                    <Button variant="text" color="primary">History</Button>
                </Link>
				page to search for previously uploaded/processed reports
			</BodyText>

            <BodyText align="left" variant='body1'>
                Navigate to the
				<Link to="/uploader" >
                    <Button variant="text" color="primary">Uploader</Button>
                </Link>
				page to upload a report bundle
			</BodyText>

            <BodyText align="left" variant='body1' style={{ marginTop: 8 }}>
                Use a link provided by the mobile app to link directly into a specific report
            </BodyText>
        </div>
    );
}
