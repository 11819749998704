import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { DataEntry, Range, ReportInfo, RunStats } from '../../helpers/types';
import { BaseActions, BaseState, RootState } from '../types';

export enum AccountActions {
	INIT = 'ACCOUNT_INIT',
	SET_NAME = 'ACCOUNT_SET_NAME',
	SET_FIRST_NAME = 'ACCOUNT_SET_FIRST_NAME',
	SET_LAST_NAME = 'ACCOUNT_SET_LAST_NAME',
	SET_EMAIL = 'ACCOUNT_SET_EMAIL',
	SET_PHONE = 'ACCOUNT_SET_PHONE',
	SET_PASSWORD = 'ACCOUNT_SET_PASSWORD',

	UPDATE_PROPERTY = 'ACCOUNT_UPDATE_PROPERTY',
	UPDATE_EMAIL = 'ACCOUNT_UPDATE_EMAIL',

	SET_REFRESHING = 'ACCOUNT_SET_REFRESHING',

	UPDATE_POLLING = 'ACCOUNT_UPDATE_POLLING',

	RESET = 'ACCOUNT_RESET'
}

export type PropertyTypes = 'name' | 'firstName' | 'lastName' | 'phone' | 'email';

export interface UserProperty<T extends PropertyTypes> {
	type: T;
	value?: string;
	newValue?: string;
	isUpdating?: boolean;
	errorUpdating?: boolean;
}

export interface EmailProperty extends UserProperty<'email'> {
    emailVerified?: boolean;
    sendingVerifyEmail?: boolean;
    sentVerifyEmail?: boolean;
}

export interface EmailVerificationStatus {
	isVerified?: boolean;
	sendingVerifyEmail?: boolean;
    sentVerifyEmail?: boolean;
}

export interface AccountState extends BaseState<AccountActions>, PersistedAccountState {
	id: string,
	isRefreshing: boolean,
	pollingId: NodeJS.Timeout,
	isPolling: boolean,
	name: UserProperty<'name'>,
	firstName: UserProperty<'firstName'>,
	lastName: UserProperty<'lastName'>,
	phone: UserProperty<'phone'>,
	email: EmailProperty,
	newPassword: string
}

export interface PersistedAccountState {
	username?: string;
}

interface InitializeAccountAction extends Action<typeof AccountActions.INIT> {
	payload: {
		id: string,
        email: string;
		emailVerified: boolean;
        name: string;
        firstName: string;
        lastName: string;
        phone: string;
    };
}

interface SetRefreshingAction extends Action<typeof AccountActions.SET_REFRESHING> {
	payload: boolean;
}

export interface UpdatePropertyAction extends Action<typeof AccountActions.UPDATE_PROPERTY> {
	payload: UserProperty<PropertyTypes>;
}

interface SetNameAction extends Action<typeof AccountActions.SET_NAME> {
	payload: string;
}

interface SetFirstNameAction extends Action<typeof AccountActions.SET_FIRST_NAME> {
    payload: string;
}

interface SetLastNameAction extends Action<typeof AccountActions.SET_LAST_NAME> {
    payload: string;
}

interface SetPhoneAction extends Action<typeof AccountActions.SET_PHONE> {
	payload: string;
}

interface SetEmailAction extends Action<typeof AccountActions.SET_EMAIL> {
	payload: string;
}

interface SetPasswordAction extends Action<typeof AccountActions.SET_PASSWORD> {
	payload: string;
}

interface UpdateEmailAction extends Action<typeof AccountActions.UPDATE_EMAIL> {
    payload: EmailVerificationStatus;
}

interface UpdatePollingAction extends Action<typeof AccountActions.UPDATE_POLLING> {
    payload: {
		isPolling: boolean;
		pollingId?: NodeJS.Timeout;
    }
}

type ResetAccountStateAction = Action<typeof AccountActions.RESET>;

export type AccountActionTypes =
	InitializeAccountAction |
	SetNameAction | SetFirstNameAction | SetLastNameAction |
	SetPhoneAction | SetEmailAction | SetPasswordAction |
	SetRefreshingAction |
	UpdatePropertyAction | UpdateEmailAction |
	UpdatePollingAction |
	ResetAccountStateAction;

export type AccountThunk<ReturnType> = ThunkAction<ReturnType, RootState, undefined, AccountActionTypes>;

export function initializeAccount(id: string, name: string, firstName: string, lastName: string, phone: string, email: string, emailVerified: boolean): InitializeAccountAction {
	return {
		type: AccountActions.INIT,
		payload: {
			id,
			name,
			firstName,
			lastName,
			phone,
			email,
			emailVerified
		}
	}
}

export function setRefreshing(refreshing: boolean): SetRefreshingAction {
    return {
		type: AccountActions.SET_REFRESHING,
		payload: refreshing
    }
}

export function setUserPropertyUpdating(prop: UserProperty<PropertyTypes>, isUpdating: boolean): UpdatePropertyAction {
	return {
        type: AccountActions.UPDATE_PROPERTY,
        payload: {
			...prop,
			isUpdating
        }
    }
}

export function setPropertyNewValue(prop: UserProperty<PropertyTypes>, newValue: string): UpdatePropertyAction {
	return {
		type: AccountActions.UPDATE_PROPERTY,
		payload: {
			...prop,
			newValue
		}
	}
}

export function setUserPropertyUpdateError(prop: UserProperty<PropertyTypes>): UpdatePropertyAction {
	return {
		type: AccountActions.UPDATE_PROPERTY,
		payload: {
			...prop,
			errorUpdating: true
		}
	}
}

export function saveUserProperty(prop: UserProperty<PropertyTypes>): UpdatePropertyAction {
	return {
		type: AccountActions.UPDATE_PROPERTY,
		payload: {
			...prop,
			value: prop.newValue,
			newValue: prop.newValue
		}
	}
}

export function updateEmailStatus(status: EmailVerificationStatus): UpdateEmailAction {
    return {
		type: AccountActions.UPDATE_EMAIL,
        payload: status
    }
}

export function updatePolling(isPolling: boolean, pollingId?: NodeJS.Timeout): UpdatePollingAction {
    return {
		type: AccountActions.UPDATE_POLLING,
        payload: {
			isPolling,
			pollingId
        }
    }
}

export const resetAccountState = (): ResetAccountStateAction => ({ type: AccountActions.RESET });